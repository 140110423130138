import React, { useState, useEffect } from 'react';
import CloudTag from './cloudTag';
import useContentfull from '../shared/useContentfull';
import { mapDataFromApiResult } from '../Ekeep/WhoAreUs/service';

const CloudTagCustomerContainer = () => {
    
        const query = `
            query {
                clientsCollection {
                    items {
                        sys {
                            id
                        }
                        name,
                        image {
                            title
                            description
                            contentType
                            fileName
                            size
                            url
                            width
                            height
                        }
                    }
                }
            }
        `;
        const { data, loading, errors } = useContentfull(query);
        const [visibility, setVisibility] = useState(false);
        const [state, setState] = useState({
        data: [], cloudTagWidth: 800, cloudTagHeight: 500, logosWidth: '150', logosHeight: '150'
        })
        useEffect(() => {  
            if (!loading && data) {
                const cliInfo = mapDataFromApiResult(data);
                const entries = cliInfo.map(cust => {
                    return { 
                        image: cust.image,
                        width: cust.width,
                        height: cust.height,
                        url: cust.url,
                        target: '_top'
                    };
                })
                setState({
                    ...state,
                    data: entries,
                });
            }
        }, [data, loading, errors]);

        return (
            <>
                <CloudTag {...state} />
            </>
        )
    }

export default CloudTagCustomerContainer;
