/* eslint-disable no-underscore-dangle */
import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';


import parse from 'html-react-parser';
import { Carousel } from 'react-responsive-carousel';
import { string, arrayOf, shape } from 'prop-types';
import { ReturnBack } from '../../shared/ReturnBack';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './readmore.scss';
import './readmore.mobile.scss';

const readMorePropTypes = {
  title: string.isRequired,
  date: string.isRequired,
  content: string.isRequired,
  firstImage: string,
  imgList: arrayOf(
    shape({
      img: string.isRequired,
      imgName: string,
    }),
  ),
  subTitle: string,
};

const ReadMore = ({ ...props }) => {
  const { title, date, content, subTitle, imgList } = props;
  const history = useHistory();
  const onReturnBack = () => {
    history.push('/la-vie-avec-nous');
  };
  return (
    <section className="read-more">
      <h1 className="read-more__title">{title}</h1>
      <h2 className="read-more__sub-title">
        {subTitle ? `${subTitle} ${date}` : date}
      </h2>
      <article className="read-more__content">
        <p>{parse(content)}</p>
      </article>
      {imgList && imgList.length > 0 ? (
        <article className="read-more__images">
          <Carousel
            className="read-more__carousel"
            interval={3000}
            transitionTime={150}
          >
            {imgList.map((item) => (
              <div key={item.id}>
                <img
                  className="read-more__image"
                  alt={item.name}
                  src={item.img}
                />
              </div>
            ))}
          </Carousel>
        </article>
      ) : null}
      <ReturnBack
        classModifier="read-more"
        onReturnBack={onReturnBack}
        btnText="Retour aux articles"
      />
    </section>
  );
};

ReadMore.propTypes = readMorePropTypes;
ReadMore.defaultProps = {
  subTitle: '',
  imgList: [],
};

const readmoreContainerPropTypes = {
  match: shape({
    params: shape({ articleId: string.isRequired }),
  }).isRequired,
};

export default withRouter(ReadMore);
