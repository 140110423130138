import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import './breadcrumbs.scss';

export const propTypes = {
  crumbs: arrayOf(
    shape({
      name: string,
      path: string,
    }),
  ).isRequired,
};

const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <div className="breadcrumbs">
      {crumbs.map(({ name, path, id }, key) =>
        key + 1 === crumbs.length ? (
          <span key={id}>{name}</span>
        ) : (
          <>
            <Link key={id} className="breadcrumbs__item" to={path}>
              {name}
            </Link>
            <span className="breadcrumbs__separator">{`>`}</span>
          </>
        ),
      )}
    </div>
  );
};
Breadcrumbs.propTypes = propTypes;
export default Breadcrumbs;
