import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { shape, string } from 'prop-types';
import { withEnvironment } from '../../../../EnvironmentProvider';
import { withLoader, LoaderMode } from '../../../../shared/loader';
import ContactUsForm from './ContactUsForm';
import {
  CV_FILES,
  FEW_WORD,
  FIRST_NAME,
  LAST_NAME,
  POSITION,
  EMAIL_ADRESS,
  PHONE_NUMBER,
  validate,
 // sendMailAsync,
} from './ContactUsForm.service';

const fieldContainsError = (fields, token) => {
  const errors = Object.keys(fields).reduce((accumulator, fieldName) => {
    if (!fieldName) {
      return accumulator;
    }
    const result = fields[fieldName];
    if (fieldName !== CV_FILES && result.error) {
      accumulator.push(result.helperText);
    }

    return accumulator;
  }, []);
  return errors > 0 || token === '';
};

const fieldsWithErrorMessage = (validateFunction) => (fields) => {
  let fieldsWithMessage = {};
  Object.keys(fields).forEach((fieldName) => {
    const field = fields[fieldName];
    if (fieldName !== CV_FILES) {
      const validateResult = validateFunction(fieldName, field.value);
      fieldsWithMessage = {
        ...fieldsWithMessage,
        [fieldName]: {
          ...field,
          error: !!validateResult,
          helperText: validateResult,
        },
      };
    } else {
      fieldsWithMessage = {
        ...fieldsWithMessage,
        [fieldName]: fields[CV_FILES],
      };
    }
  });
  return fieldsWithMessage;
};

const ContactUsFormWithLoader = withLoader(ContactUsForm);

const propTypes = {
  environment: shape({
    apiUrl: string.isRequired,
  }).isRequired,
};

const fieldInitAttribute = { value: '', error: false, helperText: '' };
const initState = {
  fields: {
    [FIRST_NAME]: {
      value: '',
      error: false,
      helperText: '',
    },
    [LAST_NAME]: { ...fieldInitAttribute },
    [POSITION]: { ...fieldInitAttribute },
    [FEW_WORD]: { ...fieldInitAttribute },
    [EMAIL_ADRESS]: { ...fieldInitAttribute },
    [PHONE_NUMBER]: { ...fieldInitAttribute },
    [CV_FILES]: '',
  },
  captcha: {
    callback: 'not fired',
    value: '',
    load: false,
    delay: 1500,
  },
  loaderMode: LoaderMode.none,
};

const ContactUsFormContainer = ({ ...props }) => {
  const { environment } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  const [state, setState] = useState({
    ...JSON.parse(JSON.stringify(initState)),
  });

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const validateResult = validate(name, value);
    if (validateResult) {
      setState({
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            error: true,
            helperText: validateResult,
            value,
          },
        },
        disableButton: true,
      });
    } else {
      setState({
        ...state,
        fields: {
          ...state.fields,
          [name]: { error: false, helperText: '', value },
        },
      });
    }
  };
  const onHandleFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(new Blob(file, { type: 'application/pdf' }));
    reader.onload = (event) => {
      setState({
        ...state,
        fields: {
          ...state.fields,
          [CV_FILES]: event.target.result,
        },
      });
    };
  };
  const onChangeCaptcha = (value) => {
    setState({ ...state, captcha: { ...state.captcha, value } });
    if (value === null) {
      setState({
        ...state,
        captcha: { ...state.captcha, expired: 'true' },
      });
    }
  };
  const asyncScriptOnLoad = () => {};

  const onSendMail = async () => {
    const {
      fields,
      captcha: { value },
    } = state;
    setState({
      ...state,
      loaderMode: LoaderMode.get,
    });
    const fieldsChecked = fieldsWithErrorMessage(validate)(fields);
    if (!fieldContainsError(fieldsChecked, value)) {
      // const result = await sendMailAsync(environment.apiUrl)({
      //   token: value,
      //   mailInformations: {
      //     firstName: fields[FIRST_NAME].value,
      //     lastName: fields[LAST_NAME].value,
      //     position: fields[POSITION].value,
      //     fewWord: fields[FEW_WORD].value,
      //     phoneNumber: fields[PHONE_NUMBER].value,
      //     emailAddress: fields[EMAIL_ADRESS].value,
      //     file: fields[CV_FILES],
      //   },
      // });
      setState({
        ...state,
        loaderMode: LoaderMode.none,
      });
      if (result && result.success === true) {
        setState({ ...JSON.parse(JSON.stringify(initState)) });       
        history.push('/thanks');
      } else {
        addToast("Une erreur à eu lieu dans l'envoie du mail", {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      setState({
        ...state,
        fields: fieldsChecked,
        loaderMode: LoaderMode.none,
      });
      addToast('Données incorrectes', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  useEffect(() => {
    setState(() => ({
      ...state,
      captcha: { ...state.captcha, load: true },
    }));
  }, []);

  return (
    <ContactUsFormWithLoader
      siteKey={process.env.RAZZLE_SITE_KEY}
      onChange={onChange}
      onHandleFile={onHandleFile}
      onChangeCaptcha={onChangeCaptcha}
      asyncScriptOnLoad={asyncScriptOnLoad}
      onSendMail={onSendMail}
      {...state}
    />
  );
};

ContactUsFormContainer.propTypes = propTypes;
export default withEnvironment(ContactUsFormContainer);
