import React from 'react';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';

import './article.scss';
import './article.mobile.scss';

const propTypes = {
  _id: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  firstImage: string.isRequired,
  textButton: string,
  date: string.isRequired,
  type: string,
};

const Article = ({ ...props }) => {
  const { _id, title, firstImage, textButton, date, type } = props;
  const history = useHistory();
  const loadArticle = () => {
    history.push(`/la-vie-avec-nous/articles/${_id}`);
  };

  return (
    <article className={`post-${type}`}>
      <article className={`post-${type}__image`}>
        <img alt="images" src={firstImage} />
      </article>
      <article className={`post-${type}__content`}>
        <div className={`post-${type}__inside`}>
          <div className={`post-${type}__header`}>
            <h3 className={`post-${type}__title`}>{title}</h3>
            <h4 className={`post-${type}__date`}>{date}</h4>
          </div>
          <button
            type="button"
            className={`btn--accent post-${type}__button`}
            onClick={loadArticle}
          >
            {textButton}
            <i className="fa fa-chevron-right" />
          </button>
        </div>
      </article>
    </article>
  );
};

Article.propTypes = propTypes;
Article.defaultProps = {
  textButton: 'Read More',
  type: 'desktop',
};

const ArticleMobile = ({ ...props }) => {
  return <Article type="mobile" {...props} />;
};
const ArticleDesktop = ({ ...props }) => {
  return <Article type="desktop" {...props} />;
};

export default ({ ...props }) => {
  return (
    <>
      <ArticleDesktop {...props} />
      <ArticleMobile {...props} />
    </>
  );
};
