import React from 'react';
import { Helmet } from 'react-helmet';
import { Customer } from './Customer';
import { KeepyValors } from '../../KeepyValors'
import './values.scss';
import './values.mobile.scss';

const Values = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Mise en valeur de l'expertise des collaborateurs. Écoute et suivi du plan de carrière"
        />
      </Helmet>
      <div className="values">
        <div className="values__content">
          <Customer />          
          <KeepyValors />
        </div>
      </div>
    </>
  );
};

export default Values;
