import React, { useEffect } from 'react';
import { shape, string, arrayOf } from 'prop-types';
import Article from '../Article';

import './articleList.scss';
import './articleList.mobile.scss';

const propTypes = {
  articlesItemList: arrayOf(
    shape({
      _id: string.isRequired,
      title: string.isRequired,
      description: string.isRequired,
      firstImage: string.isRequired,
      textButton: string,
      date: string.isRequired,
    }),
  ),
};

const ArticleList = ({ articlesItemList }) => {
return (
    <section className="post-list">
      {articlesItemList && articlesItemList.length > 0 ? (
        <ul className="post-list__items">
          {articlesItemList.map((article) => (
            <li key={article._id} className="post-list__item">
              <Article {...article} textButton="En savoir plus" />
            </li>
          ))}
        </ul>
      ) : (
        <>Aucun article pour le moment</>
      )}
    </section>
  );
};

ArticleList.propTypes = propTypes;
ArticleList.defaultProps = {
  articlesItemList: [],
};

export default ArticleList;
