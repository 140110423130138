import React, { useEffect, useState } from 'react';
import './opportunities.scss';
import './opportunities.mobile.scss';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { makeStyles } from "@material-ui/core/styles"

import { CloudTagTechnologyContainer } from '../../CloudTag';


const Opportunities = ({ offer, jobs, setJob, data, title, logosWidth, logosHeight, cloudTagWidth, cloudTagHeight }) => {

    const cloudTagProps = { data: data, title: title, width: logosWidth, height: logosHeight, cloudTagWidth: cloudTagWidth, cloudTagHeight: cloudTagHeight };

    const useStyleNavAction = makeStyles({
        root: {
            'width': '14em',
            'height': '6em',
            '&:hover': {
                background: "#76C2C7",
            },
            '& .MuiBottomNavigationAction-label.Mui-selected': {
                'font-size': '1.1em',
            },
        },
        label: {
            'color': '#76C2C7',
            'font-size': '0.8em',
            'font-weight': 'bold',

        },
        selected: {
            background: "#76C2C7",
            color: "white"
        }
    });
    const useStyleNav = makeStyles({
        root: {
            'display': 'flex',
            'justify-content': 'space-between',
            'width': '40%'
        },
    });
    const actionClasses = useStyleNavAction();
    const navClasses = useStyleNav();
    const [value, setValue] = React.useState(0);

    return (
        <>
            <div className="our-opportunities-container">
                <div className="our-opportunities-container__jobs">
                    {
                        jobs && jobs.map((job) => (
                            job.active == true && (<div id={job.job} className={job.job == 'DEV' ? 'our-opportunities-container__jobSelected' : 'our-opportunities-container__job'}>
                                <h6 onClick={() => setJob(job.job)}>
                                    {job.title}
                                </h6>
                            </div>)
                        ))
                    }
                </div>
                <div className="our-opportunities-container__fiche">
                    <>
                        {offer.description &&
                            <>
                                <h4>Description</h4>
                                < ul >
                                    {
                                        offer.description
                                    }
                                </ul>
                            </>
                        }
                        {offer.jobs &&
                            <>
                                <h4>Poste</h4>
                                < ul >
                                    {
                                        offer.jobs.map((job) => (

                                            <li>
                                                {job.title}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {offer && offer.sectors &&
                            <>
                                <h4>Secteurs</h4>
                                < ul >
                                    {
                                        offer.sectors.map((sector) => (

                                            <li>
                                                {sector.title}
                                            </li>

                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {offer && offer.goodPratices &&
                            <>
                                <h4>Bonnes pratiques</h4>
                                < ul >
                                    {
                                        offer.goodPratices.map((goodPratice) => (

                                            <li>
                                                {goodPratice.title}
                                            </li>

                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {offer && offer.mindsets &&
                            <>
                                <h4>Etat d'esprit</h4>
                                < ul >
                                    {
                                        offer.mindsets.map((mindset) => (

                                            <li>
                                                {mindset.title}
                                            </li>

                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {offer && offer.rules &&
                            <>
                                <h4>Rôles</h4>
                                < ul >
                                    {
                                        offer.rules.map((rule) => (

                                            <li>
                                                {rule.title}
                                            </li>

                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {offer && offer.qualities &&
                            <>
                                <h4>Etat d'esprit</h4>
                                < ul >
                                    {
                                        offer.qualities.map((quality) => (

                                            <li>
                                                {quality.title}
                                            </li>

                                        ))
                                    }
                                </ul>
                            </>
                        }
                        {offer && offer.technologies &&
                            <>
                                <h4>Technologies</h4>

                                <CloudTagTechnologyContainer />

                            </>
                        }
                    </>


                </div>

                <div className="our-opportunities-container__experience">
                    <h4>Retours d'expériences</h4>
                    <Carousel
                        className="our-opportunities-container__experience__carousel"
                        showThumbs={false}
                        showArrows={true}
                        showStatus={false}
                        swipeable={true}
                        interval={4000}
                        transitionTime={350}
                        autoPlay={false}
                        infiniteLoop={true}

                    >
                        {offer.feedBacks && offer.feedBacks.map((feed) => (
                            <div className='our-opportunities-container__experience__quoteContainer'>
                                <>
                                    <blockquote>
                                        {feed.feed}
                                    </blockquote>
                                </>
                            </div>
                        ))

                        }
                    </Carousel>

                </div>
            </div>


        </>
    );
};

export default Opportunities;