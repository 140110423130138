import React, { useEffect } from 'react';

import './legalNotice.scss';
import './legalNotice.mobile.scss';

const fadeInFadeOut = () => () => {
  const header = window.document.getElementById('header-bottom');
  const title = window.document.getElementById('legals-mentions-title');
  if (window.pageYOffset > header.offsetTop) {
    title.classList.add('legals-mentions-title--fade-in');
  } else {
    title.classList.remove('legals-mentions-title--fade-out');
  }
};

const LegalNotice = () => {
  useEffect(() => {
    fadeInFadeOut();
  });
  return (
    <>
      <section className="legal-notice">
        <div className="legals-mentions-title" id="legals-mentions-title">
          MENTIONS LEGALES
        </div>
        <article className="legal-information">
          <header>
            <h3>Informations légale</h3>
          </header>
          <ul>
            <li>
              SASU Société par actions simplifiée à associé unique au capital de
              10 000 euros
            </li>
            <li>
              Siège social: 165 avenue de la Marne, 59700 Marcq en Baroeul, TVA
            </li>
            <li>
              France Immatriculation au RCS: 882 865 744 R.C.S. Lille Metropole
            </li>
            <li>
              Intracommunautaire : FR22 88 28 65 744 SIRET : 882 865 744 000 16
            </li>
          </ul>
        </article>
        <article className="about-site">
          <header>
            <h3>A propos du site</h3>
          </header>
          <ul>
            <li>Directrice de la publication: DEVYLDER Ombeline</li>
            <li>Propriétaire du site: EKEEP IT SASU</li>
            <li>
              Le responsable publication est une personne physique ou une
              personne morale
            </li>
            <li>
              Charte graphique agence BIG BIZ YOU, Blanchemaille by
              EuraTechnologies, 87 Rue du Fontenoy, 59100 Roubaix
            </li>
            <li>Crédit photo</li>
            <ul>
              <li>STUDIO 1822 / 4 Avenue de Flandre, 59700 Marcq-en-Barœul</li>
              <li>Adobe Stock</li>
            </ul>
            <li>Site hébergé par OVH Cloud</li>
          </ul>
        </article>
        <article className="contractual-limitation">
          <header>
            <h3>Limitations contractuelles sur les données techniques</h3>
          </header>
          <p>
            Le site Internet ne pourra être tenu responsable de dommages
            matériels liés à l’utilisation du site. De plus, l’utilisateur du
            site s’engage à accéder au site en utilisant un matériel récent, ne
            contenant pas de virus et avec un navigateur de dernière génération
            mis-à-jour.
          </p>
        </article>
        <article className="handle-personal-data">
          <header>
            <h3>Gestion des données personnelles</h3>
          </header>
          <p>
            En France, les données personnelles sont notamment protégées par la
            loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
            l’article L. 226-13 du Code pénal et la Directive Européenne du 24
            octobre 1995. A l’occasion de l’utilisation du site
            http://www.ekeepit.com/, peuvent êtres recueillis : l’URL des liens
            par l’intermédiaire desquels l’utilisateur a accédé au site
            http://www.ekeepit.com.fr/, le fournisseur d’accès de l’utilisateur,
            l’adresse de protocole Internet (IP) de l’utilisateur. En tout état
            de cause EKEEPIT SASU ne collecte des informations personnelles
            relatives à l’utilisateur que pour le besoin de certains services
            proposés par le site http://www.ekeepit.com/. L’utilisateur fournit
            ces informations en toute connaissance de cause, notamment lorsqu’il
            procède par lui-même à leur saisie. Il est alors précisé à
            l’utilisateur du site http://www.ekeepit/ l’obligation ou non de
            fournir ces informations. Conformément aux dispositions des articles
            38 et suivants de la loi 78-17 du 6 janvier 1978 relative à
            l’informatique, aux fichiers et aux libertés, tout utilisateur
            dispose d’un droit d’accès, de rectification et d’opposition aux
            données personnelles le concernant, en effectuant sa demande écrite
            et signée, accompagnée d’une copie du titre d’identité avec
            signature du titulaire de la pièce, en précisant l’adresse à
            laquelle la réponse doit être envoyée. Aucune information
            personnelle de l’utilisateur du site http://www.ekeepit.com/ n’est
            publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou
            vendue sur un support quelconque à des tiers. Seule l’hypothèse du
            rachat de EKEEPIT SASU et de ses droits permettrait la transmission
            des dites informations à l’éventuel acquéreur qui serait à son tour
            tenu de la même obligation de conservation et de modification des
            données vis à vis de l’utilisateur du site http://www.ekeepit.com/.
            Le site n’est pas déclaré à la CNIL car il ne recueille pas
            d’informations personnelles. Les bases de données sont protégées par
            les dispositions de la loi du 1er juillet 1998 transposant la
            directive 96/9 du 11 mars 1996 relative à la protection juridique
            des bases de données.
          </p>
        </article>
        <article className="use-cookies">
          <header>
            <h3>Utilisation de cookies</h3>
          </header>
          <p>
            La navigation sur le site http://www.ekeepit.com/ est susceptible de
            provoquer l’installation de cookie(s) sur l’ordinateur de
            l’utilisateur. Un cookie est un fichier de petite taille, qui ne
            permet pas l’identification de l’utilisateur, mais qui enregistre
            des informations relatives à la navigation d’un ordinateur sur un
            site. Les données ainsi obtenues visent à faciliter la navigation
            ultérieure sur le site, et ont également vocation à permettre
            diverses mesures de fréquentation. Le refus d’installation d’un
            cookie peut entraîner l’impossibilité d’accéder à certains services.
            Chaque navigateur étant différent, nous vous invitons à consulter
            l’aide de votre navigateur pour en savoir plus sur la façon de
            paramétrer l’acceptation des cookies.
          </p>
        </article>
        <article className="applicable-law-and-legal-attribution">
          <header>
            <h3>Droit applicable et attribution juridique</h3>
          </header>
          <p>
            Tout litige en relation avec l’utilisation du site
            http://www.ekeepit.com/ est soumis au droit français. Il est fait
            attribution exclusive de juridiction aux tribunaux compétents de
            Paris.
          </p>
        </article>
      </section>
    </>
  );
};

export default LegalNotice;
