export const mapDataFromApiResult = (data) => {
  const {
    collaborateursCollection: { items: collaborators },
  } = data;
  return {
    ceo: getCeoFromCollaboratorList(collaborators),
    castMemberList: getCastMemberListFromCollaboratorList(collaborators)
  }
};

const getCastMemberListFromCollaboratorList = (collaboratorList) =>
  collaboratorList.filter(
    (castMember) => !castMember.isCto,
  ).map(castMember => mapCollaboratorData(castMember));

const getCeoFromCollaboratorList = (collaboratorList) =>
  mapCollaboratorData(collaboratorList.find((castMember) => castMember.isCto));

const mapCollaboratorData = (collaborator) => {
  const { sys: { id }, ...rest } = collaborator;
  return {
    id,
    ...rest
  }
};