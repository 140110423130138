import React from 'react';

import './sitemap.scss';
import './sitemap.mobile.scss';

const SiteMap = () => {
  return (
    <>
      <section className="site-map">
        <div className="site-map-title" id="site-map-title">
          Plan du site
        </div>
        <article className="site-map__home">
          <header>
            <h3>PAGE D’ACCEUIL</h3>
          </header>
          <ul>
            <li>MENTIONS LEGALES</li>
            <li>PLAN DU SITE</li>
            <li>LIEN PAGE LINKDIN EKEEP IT</li>
            <li>LIEN CONTACT</li>
          </ul>
        </article>
        <article className="site-map__who-are-us-page">
          <header>
            <h3>PAGE QUI SOMMES NOUS</h3>
          </header>
          <ul>
            <li>L’EKEEP</li>
            <li>ILS NOUS FONT CONFIANCE</li>
          </ul>
        </article>
        <article className="site-map__values-page">
          <header>
            <h3>PAGE NOS VALEURS</h3>
          </header>
          <ul>
            <li>CLIENTS</li>
            <li>CONSULTANTS</li>
          </ul>
        </article>
        <article className="site-map__meet-ekeep-page">
          <header>
            <h3>PAGE REJOINDRE L’EKEEP</h3>
          </header>
          <ul>
            <li>RETROUVEZ NOS OFFRES SUIVANT VOTRE PROFIL</li>
            <li className="site-map__meet-ekeep-page--style-none">
              <ul>
                <li>JE SUIS ORIENTE(E) OPEN SOURCE JAVA</li>
                <ul>
                  <li>POSTE DEVELOPPEUR OPEN SOURCE JAVA</li>
                  <li>POSTE TECHNICAL LEADER OPEN SOURCE JAVA</li>
                  <li>POSTE CHEF DE PROJET OPEN SOURCE JAVA</li>
                </ul>
              </ul>
              <ul>
                <li>JE SUIS ORIENTE(E) MICROSOFT</li>
                <ul>
                  <li>POSTE DEVELOPPEUR MICROSOFT</li>
                  <li>POSTE TECHNICAL LEADER MICROSOFT</li>
                  <li>POSTE CHEF DE PROJET MICROSOFT</li>
                </ul>
              </ul>
              <ul>
                <li>AUTRES</li>
                <ul>
                  <li>POSTE DEVOPS</li>
                </ul>
              </ul>
            </li>
            <li>JE VEUX REJOINDRE L’EKEEP</li>
          </ul>
        </article>
        <article className="site-map__life-with-us-page">
          <header>
            <h3>LA VIE AVEC NOUS</h3>
          </header>
        </article>
      </section>
    </>
  );
};

export default SiteMap;
