import React from 'react';
import { ContactUsForm, CeoContact } from './Contacts';
import { Offers } from './Offers';

import './meetEkeep.scss';
import './meetEkeep.mobile.scss';

export const ContactAndCeo = () => {
  return (
    <div className="meet-ekeep__contact">
      <div className="meet-ekeep__contact-ceo">
        <CeoContact />
      </div>
    </div>
  );
};

const MeetEkeep = () => {
  return (
    <section key="esn-meet-ekeep" className="meet-ekeep">
      <section className="meet-ekeep__offers-section">
        <Offers />
      </section>
      <section id="meet-ekeep" className="meet-ekeep__contact-section">
        <h2>Je veux rejoindre l'ékeep</h2>
        <ContactAndCeo />
      </section>
    </section>
  );
};

export default MeetEkeep;
