import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import PostAndInformation from './Post';

import withOverlay from './withOverlay';

const microCeoPropType = {
  memberProfile: shape({
    firstName: string.isRequired,
    sys: shape({ id: string.isRequired }),
    post: string.isRequired,
    presentation: string.isRequired,
    principalMotivation: shape({
      json: shape({
        content: arrayOf(
          shape({
            content: arrayOf(
              shape({
                value: string.isRequired,
              }).isRequired,
            ),
          }).isRequired,
        ),
      }).isRequired,
    }).isRequired,
    descriptionPhoto: shape({ url: string.isRequired }),
  }).isRequired,
};

const MicroCeoComponent = ({ memberProfile }) => {
  const {
    firstName,
    id,
    post,
    principalMotivation,
    presentation,
    descriptionPhoto,
  } = memberProfile;

  return (
    <div className="ekeep-micro ekeep-micro-ceo">
      <div className="ekeep-micro-ceo__photo-container">
        <img
          id={`ekeep-micro-ceo__photo_${id}`}
          alt={firstName}
          src={descriptionPhoto.url}
        />
      </div>
      <div className="ekeep-micro-ceo__description">
        <PostAndInformation firstName={firstName} post={post} type="ceo" />
        <div className="ekeep-micro-ceo__description_second-part">
          <div className="ekeep-micro-ceo__description_second-part_why-ekeep-it">
            <h2 className="ekeep-micro-ceo__description_second-part_why-ekeep-it-title">
              Pourquoi créer ékeep IT ?
            </h2>
            <p>{presentation}</p>
          </div>
          <div className="ekeep-micro-ceo__description_second-part_motivation">
            <h2 className="ekeep-micro-ceo__description_second-part_motivation-title">
              Motivation principale :
            </h2>
            <h4>L’HUMAIN</h4>
            <p>{principalMotivation.json.content[0].content[0].value}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
MicroCeoComponent.propTypes = microCeoPropType;
MicroCeoComponent.defaultPropTypes = {
  presentation: '',
  expertise: '',
};

export default withOverlay(MicroCeoComponent);
