import React, { useState, useEffect } from 'react';
import Opportunities from "./Opportunities";
import { Offers } from './Offers';

import MicroIcon from '../../shared/images/microsoft-icon.svg';
import { ReactComponent as JavaIcon } from '../../shared/images/java-icon.svg';
import { ReactComponent as DevOpsIcon } from '../../shared/images/devops-logo.svg';

const OurOpportunities = () => {

    const [state, setState] = useState({
        offer: {}, job: 'DEV', jobs: []
    })

    const jobs = {
        'dev': 'DEV',
        'archi': 'ARCHI',
        'devops': 'DEVOPS',
        'tl': 'TL',
        'cp': 'CP'
    }

    const doFilter = () => {
        var offers = Offers;

        if (state.job) {
            offers = offers.filter((offer) => {
                return offer.job == state.job;
            });
        }

        return offers[0];
    }

    const setJob = (job) => {
        setState({
            ...state,
            job: job
        })
    }

    const selectDivJob = () => {
        var parent = document.getElementsByClassName("our-opportunities-container__jobs");
        if (parent && parent[0].children.length > 0) {
            for (var i = 0; i < parent[0].children.length; i++) {
                var child = parent[0].children[i];
                if (child.id == state.job) {
                    child.className = "our-opportunities-container__jobSelected";
                }
                else {
                    child.className = "our-opportunities-container__job";
                }

            }
        }
    }

    useEffect(() => {

        var offer = doFilter();

        var jobs = [...new Set(Offers.map(offer => {
            return {
                title: offer.title,
                job: offer.job,
                active:offer.active
            }
        }))];

        setState({
            ...state,
            offer: offer,
            jobs: jobs,
        })

        selectDivJob();
    }, [state.job]);

    return (
        <Opportunities {...state} setJob={setJob} />
    )

}

export default OurOpportunities;