import React from 'react';
import { func, string } from 'prop-types';
import AssignmentReturnOutlinedIcon from '@material-ui/icons/AssignmentReturnOutlined';

import './returnback.scss';
import './returnback.mobile.scss';

const propTypes = {
  onReturnBack: func.isRequired,
  btnText: string,
  classModifier: string,
};

const ReturnBack = ({ onReturnBack, btnText, classModifier }) => {
  const baseClass = 'return-back';

  return (
    <div
      className={
        classModifier
          ? `${baseClass} ${baseClass}--${classModifier}`
          : baseClass
      }
      onClick={onReturnBack}
    >
      <AssignmentReturnOutlinedIcon
        className={
          classModifier
            ? `${baseClass}__icon ${baseClass}__icon--${classModifier}`
            : `${baseClass}__icon`
        }
      />
      <p
        className={
          classModifier
            ? `${baseClass}__text ${baseClass}__text--${classModifier}`
            : `${baseClass}__text`
        }
      >
        {btnText}
      </p>
    </div>
  );
};

ReturnBack.propTypes = propTypes;
ReturnBack.defaultProps = {
  btnText: '',
  classModifier: '',
};

export default ReturnBack;
