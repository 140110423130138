/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { HeaderPart } from './Header';
import { Home } from './Home';
import { FooterPart } from './Footer';
import { LegalNotice } from './LegalNotice';
import { SiteMap } from './SiteMap';
import { OurOpportunities } from './Ekeep/OurOpportunities';
import { ContactUsThanks } from './Ekeep/MeetEkeep/Contacts';
import withCookieConsent from './withCookieConsent';
import withTracker from './withTracker';
import {Apply} from './Ekeep/Apply'

import {
  LifeWithUs,
  MeetEkeep,
  WhoAreUs,
  Values,
  ContactAndCeo,
  DetailsOffersMicrosoft,
  DetailsOffersJava,
  ChefMicrosoft,
  DevJava,
  DevMicrosoft,
  OfferDevOps,
  ProjectChefJava,
  TlJava,
  TlMicrosoft,
  ReadMore
} from './Ekeep';
import ScrollToTopOnMount from './shared/ScrollTopOnMount';

import './routes.scss';



const withPage = (Component) => (props) => {
  const crumbs = routesList
    .filter(({ path }) => props.match.path.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(props.match.params).length
        ? Object.keys(props.match.params).reduce(
            (path, param) =>
              path.replace(`:${param}`, props.match.params[param]),
            path,
          )
        : path,
      ...rest,
    }));

  useEffect(() => {
   
    
   
    return () => {
     
      
    };
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      <HeaderPart crumbs={crumbs} />
      <Component {...props} />
      <FooterPart {...props} />
    </>
  );
};

const routesList = [
  {
    id: '4cbbe5f1-8b74-4248-bb89-1cde5a5f8a74',
    exact: true,
    path: '/',
    name: 'Accueil',
    Component: withTracker(withPage(Home)),
  },
  {
    id: 'e45e1963-f2ba-4e28-a0ba-2cb8a5a5daf4',
    exact: false,
    path: '/qui-sommes-nous',
    name: 'Qui sommes-nous',
    Component: withTracker(withPage(WhoAreUs)),
  },
  {
    id: '619dbaba-12b4-43d8-8aaf-41aeec1413f2',
    exact: false,
    path: '/nos-valeurs',
    name: 'Nos valeurs',
    Component: withTracker(withPage(Values)),
  },
  {
    id: 'c6fc7539-61c3-4135-a2f2-5265b39d679a',
    exact: false,
    path: '/contact',
    name: 'Contact',
    Component: withTracker(withPage(ContactAndCeo)),
  },
  {
    id: '23cd4de7-10f2-4fa6-be3a-0a9a5c95e1c9',
    exact: true,
    path: '/nos-opportunites',
    name: "Nos opportunitès",
    Component: withTracker(withPage(OurOpportunities)),
  },
  {
    id: 'b5c9c79d-4110-4c7a-88f4-e0e5264587bb',
    exact: true,
    path: '/la-vie-avec-nous',
    name: 'La vie avec nous',
    Component: withTracker(withPage(LifeWithUs)),
  },
{
    id: 'a48ca4f9-f24c-47e9-9aeb-dd2fb5e7ffef',
    exact: true,
    path: '/la-vie-avec-nous/articles',
    name: 'Articles',
    Component: withTracker(withPage(LifeWithUs)),
  },
  {
    id: '79c193b7-b6ae-463f-b96b-e518a98109c3',
    exact: false,
    path: '/la-vie-avec-nous/articles/:articleId/',
    name: 'Lire plus',
    Component: withTracker(withPage(ReadMore)),
  },
  {
    id: 'e5cd9e6a-7d0e-45d8-8234-20631c0e383d',
    exact: false,
    path: '/mentions-legales',
    name: 'Mentions légales',
    Component: withTracker(withPage(LegalNotice)),
  },
  {
    id: '98a25c90-1426-4c30-895f-2d20c0abf016',
    exact: false,
    path: '/plan-du-site',
    name: 'Plan du site',
    Component: withTracker(withPage(SiteMap)),
  },
  {
    id: '3aa940ca-04ea-437a-99bc-a852e99d3b10',
    exact: true,
    path: '/nos-opportunites/offres/microsoft',
    name: 'Offres Microsoft',
    Component: withTracker(withPage(DetailsOffersMicrosoft)),
  },
  {
    id: '15d9f225-b399-4858-824b-7fab1ee763cc',
    exact: true,
    path: '/nos-opportunites/offres/java',
    name: 'Offres Java',
    Component: withTracker(withPage(DetailsOffersJava)),
  },
  {
    id: '2117d473-0447-4f53-8840-22a22e866b7f',
    exact: true,
    path: '/nos-opportunites/offres/microsoft/developpeur',
    name: 'Développeur Microsoft',
    Component: withTracker(withPage(DevMicrosoft)),
  },
  {
    id: 'd59b90ec-7766-427f-a388-150afd1fc378',
    exact: true,
    path: '/nos-opportunites/offres/microsoft/leader-technique',
    name: 'Leader Technique Microsoft',
    Component: withTracker(withPage(TlMicrosoft)),
  },
  {
    id: 'da0bf8be-5528-49d2-a829-aa82889e3b41',
    exact: true,
    path: '/nos-opportunites/offres/microsoft/chef-de-projet',
    name: 'Chef de projet Microsoft',
    Component: withTracker(withPage(ChefMicrosoft)),
  },
  {
    id: '70402569-f83d-4a04-b5df-94c00f39d5fc',
    exact: true,
    path: '/nos-opportunites/offres/java/developpeur',
    name: 'Développeur Java',
    Component: withTracker(withPage(DevJava)),
  },
  {
    id: '15309676-688b-4dd3-8007-2c09108668f8',
    exact: true,
    path: '/nos-opportunites/offres/java/leader-technique',
    name: 'Leader Technique Java',
    Component: withTracker(withPage(TlJava)),
  },
  {
    id: '046b9da8-8a54-4769-9794-d6b02bf549a2',
    exact: true,
    path: '/nos-opportunites/offres/java/chef-de-projet',
    name: 'Chef de projet Java',
    Component: withTracker(withPage(ProjectChefJava)),
  },
  {
    id: 'a0344da5-3d91-4c6c-bb92-1d545a15f944',
    exact: true,
    path: '/nos-opportunites/offres/devops',
    name: 'Offres DevOps',
    Component: withTracker(withPage(OfferDevOps)),
  },
  {
    id: '3012999c-feaa-444e-8d9a-c44db973ebfa',
    exact: true,
    path: '/je-postule',
    name: 'Je postule',
    Component: withTracker(withPage(Apply)),
  },
  {
    id: 'A55e1963-f2ba-4e28-a0ba-2cb8a5a5daG8',
    exact: false,
    path: '/thanks',
    name: 'Merci',
    Component: withTracker(withPage(ContactUsThanks)),
  },
];

const Routes = () => {
  return (
    <Switch>
      {routesList.map(({ id, path, name, Component, ...otherProps }) => (
        <Route
          path={path}
          key={id}
          render={(props) => <Component {...props} name={name} />}
          {...otherProps}
        />
      ))}
    </Switch>
  );
};

export default withCookieConsent(Routes);
