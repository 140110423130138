import React from 'react';
import './keepyvalors.scss'
import './keepyvalors.mobile.scss'

import { Keepy } from '../shared/images/valors/keepy_valors.svg';
import { Arm } from '../shared/images/valors/bras3.svg';

const KeepyValors = () => {

    return (
        <>
            <h2 className="keepyvalors-container__title">Ce que nous proposons à nos consultants</h2>
            <div className="keepyvalors-container">
               
                <div className="keepyvalors-container__svgs">
                    <Keepy className="keepyvalors-container__keepy" />
                </div>
                <div className="keepyvalors-container__valors">
                    <div id="val1" className="keepyvalors-container__valor">Mise en valeur de leur expertise via des formations et audit clients</div>
                    <div id="val2" className="keepyvalors-container__valor">Intégration dans la stratégie de la société</div>
                    <div id="val3" className="keepyvalors-container__valor">Ecoute et suivi du plan de carrière</div>
                    <div id="val4" className="keepyvalors-container__valor">Suivi sur mesure au quotidien</div>
                    <div id="val5" className="keepyvalors-container__valor">Missions en adéquation avec leurs attentes</div>
                    <div id="val6" className="keepyvalors-container__valor">Package intéressant</div>
                    <Arm id="arm" className="keepyvalors-container__arm" />
                </div>
            </div>

        </>
    )
}

export default KeepyValors;

