import React from 'react';
import Member from './Member';
import { EkeepMicroComponent } from '../Micro';

import './ekeep.macro.scss';
import './ekeep.macro.mobile.scss';

const EkeepMacroComponent = ({ ...props }) => {
  const {
    ceo,
    openProfile,
    memberProfile,
    castMembers,
    visibility,
    setVisibility,
  } = props;
  return (
    <div className="ekeep-macro">
      <h2 className="ekeep-macro__title">L’ékeep</h2>
      <div className="ekeep-macro__content">
        <div className="ekeep-macro__content--ceo">
          <Member
            id={ceo.id}
            firstName={ceo.firstName}
            src={ceo.smallPhoto.url}
            active={ceo.isActive}
            openProfile={openProfile}
            post={ceo.post}
            className="circle_ceo"
          />
        </div>
        <div className="ekeep-macro__content--member-list">
          <ul>
            {castMembers.map((collab) => (
              <li key={`${collab.id}_${collab.firstName}`} className="member">
                <Member
                  id={collab.id}
                  firstName={collab.firstName}
                  src={collab.smallPhoto.url}
                  active={collab.isActive}
                  openProfile={openProfile}
                  post={collab.post}
                  className="circle"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <EkeepMicroComponent
        visibility={visibility}
        memberProfile={memberProfile}
        setVisibility={setVisibility}
      />
    </div>
  );
};

export default EkeepMacroComponent;
