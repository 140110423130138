import Validate from 'mw.validation';

export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const POSITION = 'position';
export const EMAIL_ADRESS = 'emailAddress';
export const PHONE_NUMBER = 'phoneNumber';
export const FEW_WORD = 'fewWord';
export const CV_FILES = 'cvFiles';

const MAX_LENGTH_MESSAGE = 'Maximum atteint';

const rulesRequired = {
  required: {
    message: 'Le champ est obligatoire',
  },
};

const rules = {
  [FIRST_NAME]: [
    rulesRequired,
    {
      maxLength: {
        maxLength: 20,
        message: MAX_LENGTH_MESSAGE,
      },
    },
  ],
  [LAST_NAME]: [
    rulesRequired,
    {
      maxLength: {
        maxLength: 20,
        message: MAX_LENGTH_MESSAGE,
      },
    },
    { pattern: { regex: /.*[^ ].*/, message: 'Renseigner un nom valide' } },
  ],
  [POSITION]: [
    rulesRequired,
    {
      maxLength: {
        maxLength: 50,
        message: MAX_LENGTH_MESSAGE,
      },
    },
  ],
  [EMAIL_ADRESS]: [
    rulesRequired,
    {
      pattern: {
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Adresse mail invalide',
      },
    },
  ],
  [PHONE_NUMBER]: [
    {
      pattern: {
        regex: /^(0[1-7])(?:[ _.-]?(\d{2})){4}$/,
        message: 'Numéro de téléphone invalide',
      },
    },
  ],
  [FEW_WORD]: [
    {
      maxLength: {
        maxLength: 1000,
        message: MAX_LENGTH_MESSAGE,
      },
    },
  ],
};

export function validate(name, value) {
  const inputRules = rules[name];
  const validationResult = Validate.validation.firstError(
    Validate.validation.validateView(value, inputRules),
  );
  return validationResult != null ? validationResult.message : null;
}

// export const sendMailAsync = (baseUrl) => async (data) => {
//   return fetch(`${baseUrl}/send-email`, {
//     method: 'post',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json; charset=utf-8',
//     },
//     body: data ? JSON.stringify(data) : null,
//   })
//     .then((res) => res.json())
//     .then((result) => {
//       return result;
//     });
// };
