export const Offers = [
  {
    id: '4ee85f28-0898-4efc-bd46-6bacfe3816d1',
    title: 'Concepteur Développeur',
    job: 'DEV',
    active: true,
    jobs: [
      {
        id: 'cf6f72ea-f439-453a-899a-e1bfa798f492',
        title:
          'Participation à l’analyse afin de cerner les besoins des utilisateurs avant d’établir un plan de programmation',
      },
      {
        id: 'fbb941e6-0cd8-40de-8f08-fae9ed42bd6f',
        title: 'Identifier les solutions techniques à apporter',
      },
      {
        id: 'a9deb160-5413-4c0a-9fee-c2027e3d64b7',
        title:
          'Se charger de l’écriture du programme soit en totalité soit partiellement si une équipe de développeurs est constituée',
      },
      {
        id: 'c136cea5-6624-4a03-b9e9-ef44bef43168',
        title:
          'Utiliser des progiciels qui facilitent le travail de programmation et de codage informatique',
      },
      {
        id: 'c84e22d6-94bd-45b4-a0b9-bc29ddd977c3',
        title: 'Participer aux phases de test',
      },
      {
        id: 'f259660c-3451-4644-b2ab-f5093338dd50',
        title: 'Rédiger les fiches techniques d’installation et d’utilisation',
      },
      {
        id: '0b633793-7b59-4391-b5cd-4cc3d551565c',
        title:
          "S'occuper, si besoin, du soutien technique et effectuer des opérations de maintenance en cas d'erreurs après la sortie du logiciel ou de l'application",
      },
      {
        id: '0adacef3-e9d0-4dce-809b-4bc21436d334',
        title: "Faire évoluer et d'améliorer les programmes qu'il a conçus",
      },
    ],
    sectors: [
      { id: '1c93336c-fd08-44c8-bbbc-bb64d3730e9e', title: 'Retail' },
      { id: 'b9ff5081-1865-4f9a-8a94-250ae7350ce9', title: 'Assurance' },
      { id: '31369e49-7a19-4413-adb9-64834a6cf00a', title: 'Industrie' },
      { id: '9ddbc6aa-2802-45e7-a98d-81e10cf5dece', title: 'Banque' },
    ],
    technologies: [
      { id: 'a71f8860-f989-4358-a3cb-86d28a655d3f', title: 'C#' },
      { id: 'dfeedb3c-996d-4917-a7da-7f490d01afa7', title: '.Net Framework' },
      { id: '5d49dcd1-90c4-4b09-869a-b78798359bad', title: '.Net Core' },
      { id: '21cbf935-b0e1-4fe8-b8ca-36b82fc5240e', title: 'SQL Server' },
      {
        id: '495e4d1d-9a3c-4e51-8fa5-f65f4afdbf30',
        title: 'Web API (REST Architecture)',
      },
      { id: '8d1fc62f-6014-45f6-9969-2ad2a565e76c', title: 'WCF' },
      { id: 'cf43cd01-f2e2-46be-8e1b-3d9fabdca448', title: 'XUnit/ NUnit' },
      {
        id: '69f21c6e-3468-45da-ad01-b5c5febc9862',
        title: 'Utilisation d’un gestionnaire de code source (ex : Git)',
      },
    ],
    goodPratices: [
      {
        id: '72a566de-0561-4b5d-b971-f9bebc0f6c10',
        title: 'Mise en place de test unitaire (TDD, BDD)',
      },
      {
        id: 'd3bb9276-2feb-4c81-bd19-a61dac7ae93a',
        title: 'Méthodes Agiles recommandées',
      },
      {
        id: '7e2de8ac-edc8-47e9-a84c-744f4c330003',
        title: 'Intégration continue (Azure DevOps Server)',
      },
    ],
    mindsets: [
      {
        id: 'd80cffc9-c9f1-4386-8bbe-038d92565563',
        title: 'Veut se challenger',
      },
      {
        id: '0cf37e1d-5b78-4335-8149-9c8295f014f7',
        title: 'A envie de vivre une expérience de team chez ékeep IT',
      },
      {
        id: '6aeea949-3c9e-41dd-9bc4-b3bf216d8464',
        title: 'A le sens du travail et du service',
      },
      {
        id: 'ca119a28-25e7-4c3b-8156-d0eacb79ff99',
        title: 'Aime le travail d’équipe',
      },
    ],
    feedBacks: [
      {
        id: '12fa3110-a0a6-410b-9463-d7ebba8f7e87',
        feed: `-	Maintenances applicatives et évolutives

        -	Migration des applications vers une nouvelle version de Frameworks 
        
        -	Créer une dynamique permettant de favoriser et de maximiser la qualité et les bonnes pratiques de développement logiciel (TDD, méthodes agiles, clean code, pattern…)
        
        -	Me porter au service de l’équipe en étant un soutien technique. Favoriser les échanges,  instaurer un climat de confiance et de partage des connaissances.
        `,
      },
      {
        id: '64d7dd25-edae-4dc4-9d8b-40894070ecf8',
        feed: `Mon rôle au quotidien à évolué au cours de la mission. Au départ, c'était l'analyse technique du besoin du métier puis le développement correspondant.
              l'application étant active et en production j'assurais aussi la TMA. Lorsque la charge de travail s'est réduite, l'équipe s'est également réduite et je suis intervenu sur plusieurs applications différentes. Les différents "types" d'applications sur lesquelles j'ai travaillé sont les suivantes :
              -	l'application principale gère beaucoup de règles métier : gestion de prix, d'inventaires, de cycle de vie de produits ...
              -	J'ai produit un POC qui servait principalement de "traducteur" entre différentes applis
              une application de présentation de résultat et d'indicateurs de performances.
              Aujourd'hui mon travail consiste à faire la migration des anciens écrans écrits en jsp en écran Réact pour l'application principale.
              J'interviens encore ponctuellement sur celle qui présente des résultats 
        `,
      }
    ]
  },
  {
    id: '64dfe4ad-9b75-4673-a847-5b4537e6f9e4',
    title: 'DevOps',
    job: 'DEVOPS',
    active: true,
    jobs: [
      {
        id: '52029bb4-bd5c-48ee-b944-724908ed46d0',
        title: 'Se charger de déployer régulièrement les applications',
      },
      {
        id: 'bfd9798b-f96e-408a-a4fb-a18afa77190a',
        title: 'Faire des tests dans un environnement similaire à celui de production',
      },
      {
        id: '6c374cc2-a046-49d7-8ba7-8b9851c9f367',
        title: 'Surveiller étroitement l’exploitation et la qualité de production via des métriques',
      },
      {
        id: '41c0e059-dc70-4c6a-913e-4815b38774ab',
        title: 'Orchestrer et résoudre les problèmes de scalabilité, d’automatisation et d’industrialisation',
      },
    ],
    sectors: [
      { id: '1c93336c-fd08-44c8-bbbc-bb64d3730e9e', title: 'Retail' },
      { id: 'b9ff5081-1865-4f9a-8a94-250ae7350ce9', title: 'Assurance' },
      { id: '31369e49-7a19-4413-adb9-64834a6cf00a', title: 'Industrie' },
      { id: '9ddbc6aa-2802-45e7-a98d-81e10cf5dece', title: 'Banque' },
    ],
    technologies: [
      {
        id: 'a71f8860-f989-4358-a3cb-86d28a655d3f',
        title: 'Programmation (Java et/ou C# et/ou …)',
      },
      { id: 'dfeedb3c-996d-4917-a7da-7f490d01afa7', title: 'Administration système UNIX et/ou Windows' },
      { id: '5d49dcd1-90c4-4b09-869a-b78798359bad', title: 'Scripts (ex: Bash, Python, ...)' },
      {
        id: '21cbf935-b0e1-4fe8-b8ca-36b82fc5240e',
        title: 'Outil d’automatisation (ex: Ansible, Puppets, ...)',
      },
      {
        id: '495e4d1d-9a3c-4e51-8fa5-f65f4afdbf30',
        title: 'Intégration continue (ex: Jenkins, Gitlab CI, ...)',
      },
      { id: '8d1fc62f-6014-45f6-9969-2ad2a565e76c', title: 'Conteneurisation (Docker, Kubernetes, …)' },
      {
        id: 'cf43cd01-f2e2-46be-8e1b-3d9fabdca448',
        title: 'Cloud (AWS et/ou Azure et/ou GCP)',
      },
    ],
    goodPratices: [{ id: 'd3bb9276-2feb-4c81-bd19-a61dac7ae93a', title: 'Méthodes Agiles recommandées' }],
    mindsets: [
      { id: 'd80cffc9-c9f1-4386-8bbe-038d92565563', title: 'Veut se challenger' },
      {
        id: '0cf37e1d-5b78-4335-8149-9c8295f014f7',
        title: 'A envie de vivre une expérience de team chez ékeep IT',
      },
      {
        id: '6aeea949-3c9e-41dd-9bc4-b3bf216d8464',
        title: 'A le sens du travail et du service',
      },
      {
        id: 'ca119a28-25e7-4c3b-8156-d0eacb79ff99',
        title: 'Aime le travail d’équipe',
      },
    ],
    feedBacks: [
      {
        id: '28d3aa0d-b4d0-445f-b1c4-a7a4979ec610',
        feed: `Actuellement dans une équipe de 8 devOPS, mon rôle est de gérer la partie RUN du projet et de participer à des problématiques de BUILD et d\'automatisation.Les technos que nous utilisons le plus sont Jenkins, Jira, Git et Python pour écrire des scripts. En plus de ces technos, nous utilisons d\'autres outils de manière beaucoup plus ponctuelles que ce soit pour du monitoring ou de la gestion d\'accès.`,
      }
    ]
  },
  {
    id: '8b3351ac-999f-4b8e-9cb4-1b15c662dd50',
    title: 'Leader technique',
    job: 'TL',
    active: true,
    jobs: [
      {
        id: '08c6d091-1238-473d-9563-4b39bc2c47d1',
        title:
          'Superviser le projet technique et s’assurer que le budget et les délais sont tenus',
      },
      {
        id: '5293e2e3-14ca-48e4-8a7d-4cc922d7d8b7',
        title: 'Accompagner et gérer les savoir-faire de son équipe technique',
      },
      {
        id: 'f6bd5581-e6b0-499c-8298-767f160db01b',
        title: 'Assurer la qualité du code fournit par l’équipe',
      },
      {
        id: '7de83afb-d142-4ad8-b338-37359294ee8f',
        title: 'Faire une veille permanente sur les évolutions technologiques',
      },
      {
        id: '4c70ac44-7f21-4c8a-8ce0-785d4b59dd54',
        title: 'Résoudre les problèmes techniques rencontrés par son équipe',
      },
      {
        id: '0e2274f5-65de-453b-86c1-5c643f4e6758',
        title: 'Mettre en place et réaliser des phases de test',
      },
      {
        id: '37da62b9-6a4e-4767-a788-f3ec78b2632d',
        title: 'Prendre en charge des parties spécifiques de développements',
      },
    ],
    sectors: [
      { id: '1a74bcbf-7c78-423e-ac76-6e99d58297b0', title: 'Retail' },
      { id: '587c92da-e2b5-4793-abd6-447aa638d151', title: 'Assurance' },
      { id: '5237a809-5714-4f94-bf86-2a9c1a3c53f4', title: 'Industrie' },
      { id: 'd0edd910-b7c3-43c9-878c-d6c7197a02cc', title: 'Banque' },
    ],
    technologies: [
      { id: '874bd8bc-e5fa-4724-9518-6bf256817baa', title: 'C#' },
      { id: '25c97b35-ac25-4d0b-9226-63e0b444a116', title: '.Net Framework' },
      { id: '608e8719-9b4f-4548-b81a-4e88b36c1cff', title: '.Net Core' },
      { id: '21aa5c53-8f9d-4692-b16f-2772e66c4574', title: 'SQL Server' },
      {
        id: '9eb4f6f7-6a9e-4ad7-80f4-5af8d2780c32',
        title: 'Web API (REST Architecture)',
      },
      { id: '41ad3db3-6d2a-404d-bc69-95c642103287', title: 'WCF' },
      { id: 'f0ef9dc1-32c4-400f-83b8-600876283df0', title: 'XUnit/ NUnit' },
      {
        id: '7618e7e1-fa55-4dde-b687-b1c33e257077',
        title: 'Utilisation d’un gestionnaire de code source (ex : Git)',
      },
    ],
    goodPratices: [
      {
        id: '7b1e330c-b826-4de0-940d-b5d19a7819a0',
        title: 'Mise en place de test unitaire (TDD, BDD)',
      },
      {
        id: '712f1389-1189-485c-a6c4-93fad0bc8867',
        title: 'Méthodes Agiles recommandées',
      },
      {
        id: 'f6b21a5d-6ffd-4961-8eca-9b4bde445e07',
        title: 'Intégration continue (Azure DevOps Server)',
      },
    ],
    mindsets: [
      {
        id: 'd80cffc9-c9f1-4386-8bbe-038d92565563',
        title: 'Veut se challenger',
      },
      {
        id: '0cf37e1d-5b78-4335-8149-9c8295f014f7',
        title: 'A envie de vivre une expérience de team chez ékeep IT',
      },
      {
        id: '6aeea949-3c9e-41dd-9bc4-b3bf216d8464',
        title: 'A le sens du travail et du service',
      },
      {
        id: 'ca119a28-25e7-4c3b-8156-d0eacb79ff99',
        title: 'Aime le travail d’équipe',
      },
    ],
    feedBacks: [
      {
        id: '8bfadbfb-c479-4541-bf91-36b37d3f1a15',
        feed: `-	Maintenances applicatives et évolutives 
              -	Migration des applications vers une nouvelle version de Frameworks  
              -	Créer une dynamique permettant de favoriser et de maximiser la qualité et les bonnes pratiques de développement logiciel (TDD, méthodes agiles, clean code, pattern…)
              -	Me porter au service de l’équipe en étant un soutien technique. Favoriser les échanges,  instaurer un climat de confiance et de partage des connaissances.`,
      },
      {
        id: '6ca87d81-a3d0-436d-9653-940db4c7411b',
        feed: `-	Améliorer la performance de l’application existante en repensant l’architecture
              -	Créer une dynamique permettant de favoriser et de maximiser la qualité et les bonnes pratiques de développement logiciel (TDD, méthodes agiles, clean code, pattern…)
              -	Encadrement d’un apprenti en l’accompagnant dans son parcours professionnel.
              L’équipe est constituée d’une dizaine de personnes, un responsable, deux chefs de projets, et des développeurs front et back (Java).
              Les enjeux 
                -	Déploiement d’une application de caisse (remplacement d’un ancien système encore utilisé)
                -	Créer une culture de la qualité de fabrication logiciel pour toute l’équipe`,
      }
    ]
  },
  {
    id: '8b3351ac-999f-4b8e-9cb4-1b15c662dd50',
    title: 'Référent technique',
    job: 'REF',
    active: false,
    jobs: [

    ],
    sectors: [

    ],
    technologies: [

    ],
    goodPratices: [

    ],
    mindsets: [

    ],
    feedBacks: [


    ]
  },
  {
    id: '64dfe4ad-9b75-4673-a847-5b4537e6f9e4',
    title: 'Architecte',
    job: 'ARCH',
    active: true,
    description:
      ` Sa mission est de définir l’architecture technique d’un système d’information et de garantir la cohérence des éléments qui le composent (réseaux, matériel, systèmes d’exploitation, bases de données et applicatifs).
     L’architecte technique doit être expert de haut niveau tout en étant généraliste pour avoir une vue d’ensemble du système d’information.
      L’évolution continue et rapide des nouvelles technologies exigent de lui des capacités d’anticipation et un esprit imaginatif pour ne pas se laisser surprendre.`
    ,
    rules: [
      {
        id: '22e61f4f-c0cb-4ec4-8a04-5846474f63d3',
        title: 'Participer à la définition des infrastructures techniques des SI',
      },
      {
        id: '582ae907-62bb-414d-91a7-efae30c9a32f',
        title: 'Préconiser des solutions techniques et chiffrer les infrastructures à mettre en œuvre',
      },
      {
        id: '13a8f650-48c2-48f1-a7d8-8633d9165218',
        title: 'Vérifier et analyser les impacts techniques des nouvelles solutions.',
      },
      {
        id: 'a6547cc2-c403-40a5-ae88-575a760cce18',
        title: 'Conseiller les équipes chargées de la réalisation',
      },
      {
        id: 'ad422cc7-5ea8-4796-ac3f-42e1686b48fe',
        title: 'Participer à tout nouveau projet ayant une incidence sur l’architecture technique ou organisationnelle',
      },
    ],
    feedBacks: [
      {
        id: 'd9d6acb2-e4a5-4bb0-aef8-8532c3fb3e31',
        feed: `Mon rôle au quotidien en tant qu'architecte technique est d'accompagner les équipes pour la mise en place de nouveaux outils et de les aider lorsqu'ils en font la demande sur des sujets à caractère technique (performance, stabilité, scaling ... etc etc). 
              Pour mener à bien cette mission, les nouvelles solutions techniques que l'on souhaite proposer sont testées de bout en bout, l'objectif est d "essuyer les plâtres , documentées et ensuite présentées aux équipes. 
              J'interviens sur des sujets très variés tels que le scaling des environnements dans GCP, la montée de version de java au sein des composants, la mise en place d'outils de dev à distance, des problématiques de performances ou encore de comportement des applications. 
              Cela nous amène à échanger avec pas mal de collaborateurs différents. 
              C'est une mission très enrichissante, sans routine mais surtout passionnante`,
      }

    ]
  },
  {
    id: 'a8f6ae96-5310-4746-9ca1-f7f2be3e8677',
    title: 'Chef de projet',
    job: 'CP',
    active: true,
    description:
      'Sous la direction du directeur de projet, vous êtes en charge de développer des projets informatiques avec le suivi, le dynamisme et la rigueur nécessaire',
    job: 'CP',
    rules: [
      {
        id: '289e6cf6-5ff8-44c7-90a4-7c4aec16ad42',
        title:
          'Suivi contractuel (voir financier) du projet à engagement de résultats, épaulé par le directeur de projet pour en assurer une rentabilité optimale',
      },
      {
        id: 'fcf3f018-83be-4c34-9b55-9a8fcafd0d0a',
        title: 'Analyse des spécifications fonctionnelles',
      },
      {
        id: 'cd51db10-a708-4580-a57c-e3841e7a2ef8',
        title: 'Rédaction des spécifications détaillées',
      },
      {
        id: 'd9049551-b0a1-4a91-996c-8674a7334e4f',
        title: 'Organisation des sprints de développement',
      },
      {
        id: '55f62b30-8289-484e-827b-51cd3f0fd32b',
        title: 'Suivi de la qualité des prestations délivrées au client',
      },
      {
        id: '65f48c0f-0f8f-405a-a8ef-3b5b588d3012',
        title: 'Pilotage des développeurs',
      },
      {
        id: '3d99f583-1246-477f-bb8a-faa458e5b0d5',
        title: "Animation quotidienne de l'équipe",
      },
      {
        id: '62f0eafc-9ff8-491f-91cd-87e28b5b2cdf',
        title: 'Participation à certaines phases de développement',
      },
    ],
    qualities: [
      {
        id: '86d0681c-1272-4bbb-86bf-a3a705683878',
        title: 'Avoir envie de se challenger',
      },
      {
        id: '076883f5-c0fb-484a-9b6a-eab637fb6800',
        title:
          'Avoir des expériences significatives sur un poste de même type.',
      },
      {
        id: '51921881-846c-4b79-9676-9acfeebe89ad',
        title: 'Être motivé et motivant.',
      },
      {
        id: 'e1513c3b-f592-4972-8b5e-a5cfcaf7cb2b',
        title: 'Avoir une bonne organisation',
      },
      {
        id: '55bc223a-b8bf-4f4c-800f-73e06754a44a',
        title: 'Être un bon communicant',
      },
      {
        id: 'afd517bd-beb0-4825-af81-5667430baf2d',
        title:
          'Savoir s’exprimer en anglais serait un plus (mais pas obligatoire)',
      },
    ],
    technologies: [
      { id: 'ee21ce3e-de1b-4ead-bed3-d34bdf8b38f4', title: 'C#' },
      { id: '883d1b7b-b771-40f3-b754-f7b77f0f05fa', title: '.Net Framework' },
      { id: '86321cb5-7587-4b12-9d30-1102a901abfd', title: '.Net Core' },
      { id: '21aa5c53-8f9d-4692-b16f-2772e66c4574', title: 'SQL Server' },
      {
        id: '42335e2a-66b4-4549-91b7-b3536b9e379c',
        title: 'Web API (REST Architecture)',
      },
      { id: '227b9908-65cb-4905-a346-32ff151cee09', title: 'WCF' },
      { id: '2e393df9-28fa-4fb7-ac26-117a8cbddfbd', title: 'XUnit/ NUnit' },
      {
        id: '0405b73d-46f5-47d9-80dc-94a1e3d2691c',
        title: 'Utilisation d’un gestionnaire de code source (ex : Git)',
      },
    ],
    feedBacks: [      
      {
        id: 'd182dc10-234b-4fad-a5e9-ea7507172b2a',
        feed: `Chef de projet digital chez un client agro-alimentaire
                Une équipe d’une quinzaine de personne
                Mon plus gros challenge : la gestion des équipes techniques et du métier,
                pour la réalisation d’une usine à site.
                Un projet passionnant et titanesque qui avait pour but de mutualiser la productivité et de créer un véritable écosystème digitale Bonduelle`,
      },
      {
        id: '5a99ad3d-09d1-4efa-be8a-095dd25ec41a',
        feed: `Chef de projet chez un client retail
                Une équipe d’une dizaine de personne
                Une mission ambitieuse, trouver un nouvel outil pricing pour gérer les prix de l’enseigne dans tous les magasins de la marque en France.
                 Un véritable challenge et un quotidien loin de la routine`,
      }
    ]
  },  
  {
    id: '4d14afb1-1641-47d5-9830-222efa6f20ba',
    title: 'Directeur de projets',
    job: 'DIR',
    active: true,
    description:
      ` La fonction d’un directeur de projet est de piloter l’ensemble des aspects organisationnels, logistiques et pratiques d’un projet donné.
      Son implication se fait dès la phase de conception jusqu’à la concrétisation.
      Son supérieur hiérarchique lui impose des délais, un budget, ainsi que des objectifs à respecter.
      Il a un rôle de coordinateur entre les services opérationnels. 
      Il est essentiel de bien faire la différence entre un chef de projet et un directeur de projet. Le premier poste consiste à encadrer la réalisation du projet.
      Il se focalise uniquement sur cette étape. Comme évoqué précédemment, le directeur a une mission pluridisciplinaire et est le supérieur hiérarchique de l’équipe.
      Il peut donc avoir sous ses ordres un ou plusieurs chefs de projet.
      `
    ,
    rules: [
      {
        id: 'ac7fd6a8-f84a-44c6-95d2-eb353e81d780',
        title: 'Encadrer le pilotage d’un projet',
      },
      {
        id: 'd87b5041-9f80-461b-8a8b-09a298f4a47d',
        title: 'Déléguer les tâches selon les compétences des membres de son équipe',
      },
      {
        id: 'f1fe0b2a-13b6-4a8f-95f4-baea793e3bdc',
        title: 'S’assurer du respect du cahier des charges',
      },
      {
        id: '85f587cb-9089-48ff-a7e9-d63b93f6c91c',
        title: 'Définir et déployer les moyens humains, techniques, matériels et financiers',
      },
      {
        id: '046c6078-eb4f-40a3-8d45-81af5dbc9632',
        title: '•	Réaliser le suivi budgétaire et la gestion administrative',
      },
    ],
    feedBacks: [      
        {
          id:'a8cf84be-a494-4e21-a037-a86801362304',
          feed: `Taille de l’équipe : 12 personnes
          Mon rôle au quotidien : piloter, manager,
          accompagner une équipe pluri-disciplinaire dans l'intégration en BU de solutions IT          
          Le plus gros chalenge sur la mission : 
          Etre le lien entre le programme et les clients, 
          representer à la fois la voix du client (les BU clientes) au sein du programme et dans le même temps
          representer pour les BUs le programme avec ses priorités, sa trajectoire, sa capacité de delivery`
        }
    ]
  },
];
