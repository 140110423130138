import React, { useState, useEffect } from 'react';

import EkeepMacroComponent from './Ekeep.macro';
import { mapDataFromApiResult } from './service';

import useContentfull from '../../../shared/useContentfull';

const query = `
    query {
      collaborateursCollection(order: order_ASC) {
        items {
          sys {
            id
          }
          firstName,
          post,
          principalMotivation {
            json
          }
          presentation,
          strongPoint,
          expertise,
          hobbies,
          smallPhoto {
              title
              description
              contentType
              fileName
              size
              url
              width
              height

            }
          descriptionPhoto {
              title
              description
              contentType
              fileName
              size
              url
              width
              height
            }
          isCto,
          isActive
        }
      }
    }
  `;

const EkeepMacroContainer = () => {
  const { data, loading, errors } = useContentfull(query);
  const [visibility, setVisibility] = useState(false);
  const [state, setState] = useState({
    memberProfile: { firstName: '', lastName: '' },
    ceo: {
      url: '',
      isActive: false,
      firstName: '',
      smallPhoto: { url: '' },
      sys: { id: '' },
      post: '',
    },
    castMembers: [],
  });
  useEffect(() => {
    if (!loading) {
      const { ceo, castMemberList: castMembers } = mapDataFromApiResult(data);
      setState({
        ...state,
        ceo,
        castMembers,
      });
    }
  }, [data, loading, errors]);

  const openProfile = (collabId) => () => {
    const { castMembers, ceo } = state;
    const member = [...castMembers, ceo].find((e) => e.id === collabId);
    setState({
      ...state,
      memberProfile: {
        ...member,
      },
    });
    setVisibility(true);
  };

  return (
    <EkeepMacroComponent
      {...state}
      visibility={visibility}
      setVisibility={setVisibility}
      openProfile={openProfile}
    />
  );
};
export default EkeepMacroContainer;
