import React, { useEffect } from 'react';
import { shape, bool, func } from 'prop-types';
import MicroCeoComponent from './Ekeep.micro.ceo';
import MicroCastMemberComponent from './Ekeep.micro.cast-member';

import './ekeep.micro.scss';
import './ekeep.micro.mobile.scss';

const ekeepMicroPropTypes = {
  visibility: bool.isRequired,
  setVisibility: func.isRequired,
  memberProfile: shape({
    isCto: bool,
    active: bool,
  }).isRequired,
};
const EkeepMicroComponent = ({ visibility, setVisibility, memberProfile }) => {
  const { isCto, isActive } = memberProfile;
  useEffect(() => {
    if (visibility) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    }
  });

  const onClose = () => {
    setVisibility(false);
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
  };

  if (!isActive) {
    return null;
  }

  if (isCto && isActive) {
    return (
      <MicroCeoComponent
        memberProfile={memberProfile}
        visibility={visibility}
        onClose={onClose}
      />
    );
  }
  return (
    <MicroCastMemberComponent
      memberProfile={memberProfile}
      visibility={visibility}
      onClose={onClose}
    />
  );
};
EkeepMicroComponent.propTypes = ekeepMicroPropTypes;

export default EkeepMicroComponent;
