import React, { useState, useEffect } from 'react';
import CloudTag from './cloudTag';
import useContentfull from '../shared/useContentfull';
import { mapDataFromApiResult } from '../Ekeep/OurOpportunities/service';

const CloudTagTechnologyContainer = () => {
    
        const query = `
            query {
                technologiesCollection {
                    items {
                        sys {
                            id
                        }
                        image {
                            title
                            description
                            contentType
                            fileName
                            size
                            url
                            width
                            height
                        }
                    }
                }
            }
        `;
        const { data, loading, errors } = useContentfull(query);
        const [visibility, setVisibility] = useState(false);
        const [state, setState] = useState({
            data: [], cloudTagWidth: 500, cloudTagHeight: 400, logosWidth: '100', logosHeight: '100'
        })
        useEffect(() => {  
            if (!loading && data) {
                const technoInfo = mapDataFromApiResult(data);
                const entries = technoInfo.map(cust => {
                    return { 
                        image: cust.image,
                        width: cust.width,
                        height: cust.height,
                        url: cust.url,
                        target: '_top'
                    };
                })
                setState({
                    ...state,
                    data: entries,
                });
            }
        }, [data, loading, errors]);

        return (
            <>
                <CloudTag {...state} />
            </>
        )
    }

export default CloudTagTechnologyContainer;
