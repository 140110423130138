export const mapDataFromApiResult = (data) => {
    const technology = data.technologiesCollection.items;
    let TechnoInfo = [];
    TechnoInfo = technology.map((techno => {
        return {
            image: techno.image.url,
            width: 100,
            height: 100,
            url: techno.image.url,
            target: '_top'
        }
    }));    
    return TechnoInfo;
};