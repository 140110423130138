import React, { useState, useRef, useEffect } from "react";

var Tick = require("ekeep-flip-numbers/tick/tick.core.module.js");
var Flip = require('ekeep-flip-numbers/dist/tick.view.flip.module');
Tick.plugin.add(Flip);

import "ekeep-flip-numbers/dist/flip.min.css";

import './flipCounter.scss';

const FlipCounter = ({ value }) => {
    const divRef = useRef();

    const tickRef = useRef();
  
    useEffect(() => {
      const currDiv = divRef.current;
      const didInit = tick => {
        tickRef.current = tick;
      };
      Tick.DOM.create(currDiv, {
        value,
        didInit,
        repeat: true,
        view: {
          children: [
            {
              root: "div",
              style: ".tick",
              repeat: true,
              children: [
                {
                  view: "flip"
                }
              ]
            }
          ]
        }
      });
      const tickValue = tickRef.current;
      return () => Tick.DOM.destroy(tickValue);
    });
  
    useEffect(() => {
      if (tickRef.current) {
        tickRef.current.value = value;
      }
    }, [value]);
  
    return <div ref={divRef} />;
};

export default FlipCounter;