import React, { useState, useEffect } from 'react';
import useContentfull from '../../shared/useContentfull';
import { mapDataFromApiResult } from '../LifeWithUs/service';
import ReadMore from './ReadMore';
import { withRouter } from 'react-router-dom';

const ReadMoreContainer = ({ match }) => {

    const query = `
    query {
        articlesCollection(where: {slug: "${match.params.articleId}"}){
        items{
            sys {
            id
            }
            slug,
            url,
            title,
            releaseDate,
            description,
            content{
            json
            },
            firstImage{
            title
            url
            },
            multiPhotoCollection{
            items{
                sys {
                id
                }
                title
                url
            }
            },
            status
        }
        }
    }
`;

    const { data, loading, errors } = useContentfull(query);
    const [visibility, setVisibility] = useState(false);
    const [state, setState] = useState({
        title: '',
        date: '',
        content: '',
        firstImage: '',
        imgList: [],
        subTitle: ''
    });

    useEffect(() => {
        if (!loading) {
        if(data) {
            const articleInfo = mapDataFromApiResult(data);
            setState({
            ...state,
            ...articleInfo[0],
        });
        }
        }
    }, [data, loading, errors]);
    
    return <ReadMore {...state}/>;

};

export default withRouter(ReadMoreContainer);