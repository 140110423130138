/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import ReactGA from 'react-ga';
import CookieConsent from 'react-cookie-consent';

import './withCookieConsent.scss';
import './withCookieConsent.mobile.scss';

export default (Component) => ({ ...props }) => {
  return (
    <>
      <Component {...props} />
      <CookieConsent
        location="bottom"
        buttonText="j'accepte"
        containerClasses="cookie-consent"
        buttonWrapperClasses="cookie-consent-btn"
        contentClasses="cookie-consent-content"
        buttonClasses="cookie-consent-btn--accept"
        cookieName="ekeep-it-consent"
        expires={150}
      >
        Ce site utilise des cookies Google Analytics à des fins de mesure
        d’audience. En poursuivant votre navigation, vous acceptez l’utilisation
        des cookies.
      </CookieConsent>
    </>
  );
};
