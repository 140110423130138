import React, { useEffect, useState } from 'react';

import './cloud-customers.scss';
import PropTypes from 'prop-types';
import SVG3DTagCloud from 'ekeep-cloud-tags/jquery.svg3dtagcloud';



const CloudTag =
  ({
    data, cloudTagWidth, cloudTagHeight
  }) => {
    var settings = {
      entries: data,
      width: '100%',
      height: '100%',
      radius: '65%',
      radiusMin: 75,
      bgDraw: true,
      bgColor: '#FFFFFF',
      opacityOver: 1.00,
      opacityOut: 0.05,
      opacitySpeed: 6,
      fov: 800,
      speed: .2,
      fontFamily: 'Oswald, Arial, sans-serif',
      fontSize: '15',
      fontColor: '#fff',
      fontWeight: 'normal',//bold
      fontStyle: 'normal',//italic 
      fontStretch: 'normal',//wider, narrower, ultra-condensed, extra-condensed, condensed, semi-condensed, semi-expanded, expanded, extra-expanded, ultra-expanded
      fontToUpperCase: true,
      tooltipFontFamily: 'Oswald, Arial, sans-serif',
      tooltipFontSize: '11',
      tooltipFontColor: '#fff',
      tooltipFontWeight: 'normal',//bold
      tooltipFontStyle: 'normal',//italic 
      tooltipFontStretch: 'normal',//wider, narrower, ultra-condensed, extra-condensed, condensed, semi-condensed, semi-expanded, expanded, extra-expanded, ultra-expanded
      tooltipFontToUpperCase: false,
      tooltipTextAnchor: 'left',
      tooltipDiffX: 0,
      tooltipDiffY: 10,
      animatingSpeed: 0.01,
      animatingRadiusLimit: 1.5

    };

    useEffect(() => {
      var svg3DTagCloud = new SVG3DTagCloud(document.getElementById('tagcloud'), settings);

    }, [data]);
    return (
      <>
        <div id="tagContainer" className="imgCloud__comp">
          <div id="tagcloud" ></div>
        </div>
      </>
    );
  };




export default CloudTag;
