export const mapDataFromApiResult = (data) => {
    
    const customer = data.clientsCollection.items;
    let cliInfo = [];
    cliInfo = customer.map((cli => {
        return {
            image: cli.image.url,
            width: 150,
            height: 150,
            url: cli.image.url,
            target: '_top'
        }
    }));    
    
    return cliInfo;
};