import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import './contact-us-form.scss';
import './contact-us-form.mobile.scss';


const ContactUsThanks = () => {
  const history = useHistory();

  const backToHome = () => {
    history.push("/")
  }
  return (
    <>
      <div className="contact-form__thanks">
        <p className="contact-form__thanks__text">Merci d'avoir postulé</p>
        <Button
          variant="contained"
          color="primary"
          className="contact-form__thanks__home"
          onClick={backToHome}
        >
          Accueil
        </Button>
      </div>
    </>
  );
};

export default ContactUsThanks;
