import React from 'react';
import { string, arrayOf, shape, array } from 'prop-types';
import PostAndInformation from './Post';
import withOverlay from './withOverlay';

import Bounce from 'react-reveal/Bounce';

import config from 'react-reveal/globals';

config({ ssrFadeout: true });

const informationListPropTypes = {
  informations: arrayOf(
    shape({ id: string.isRequired, text: string.isRequired }),
  ).isRequired,
};

const InformationList = ({ informations }) => {
  if (informations) {
    return (
      <>
        <ul>
          {informations.map((information) => {
            const { text, id } = information;
            return <li key={id}>{text}</li>;
          })}
        </ul>
      </>
    );
  }
  return null;
};
InformationList.propTypes = informationListPropTypes;

const PersonalityListPropTypes = {
  informations: array.isRequired,
};

const PersonalityList = ({ informations }) => {
  if (informations) {
    return (
      <>
        <ul>
          {informations.map((information) => {
            return <li key={information.index}>{information}</li>;
          })}
        </ul>
      </>
    );
  }
  return null;
};
PersonalityList.propTypes = PersonalityListPropTypes;

const microCastMemberPropType = {
  memberProfile: shape({
    sys: shape({
      id: string.isRequired,
    }),
    firstName: string.isRequired,
    post: string.isRequired,
    presentation: string,
    strongPoint: array.isRequired,
    expertise: arrayOf(
      shape({
        id: string.isRequired,
        category: string.isRequired,
        items: arrayOf(
          shape({ id: string.isRequired, text: string.isRequired }),
        ),
      }),
    ),
    hobbies: array.isRequired,
    descriptionPhoto: shape({ url: string.isRequired }),
  }).isRequired,
};

const MicroCastMemberComponent = ({ memberProfile, visibility }) => {
  const {
    id,
    firstName,
    post,
    presentation,
    expertise,
    hobbies,
    strongPoint,
    descriptionPhoto,
  } = memberProfile;

  return (
    <div className="ekeep-micro ekeep-micro-cast-member">
      <Bounce ssrFadeout when={visibility}>
        <div className="ekeep-micro-cast-member__photo-container">
          <img
            id={`ekeep-micro-cast-member__photo_${id}`}
            alt={`${firstName}`}
            src={descriptionPhoto.url}
          />
        </div>
        <div className="ekeep-micro-cast-member__description">
          <PostAndInformation
            firstName={firstName}
            post={post}
            type="cast-member"
          />
          <div className="ekeep-micro-cast-member__description_second-part">
            <div className="ekeep-micro-cast-member__description_second-part_presentation">
              <p>{presentation}</p>
            </div>
            {strongPoint.length > 0 ? (
              <>
                <div className="ekeep-micro-cast-member__description_second-part_strong-point">
                  <h2 className="ekeep-micro-cast-member__description_second-part_strong-point-title">
                    Points forts
                  </h2>
                  <PersonalityList informations={strongPoint} />
                </div>
              </>
            ) : null}
            {expertise.length > 0 ? (
            <div>
              <div className="ekeep-micro-cast-member__description_second-part_expertise">
                <div className="ekeep-micro-cast-member__description_second-part_expertise-categories">
                  <h2 className="ekeep-micro-cast-member__description_second-part_expertise-title">
                    Domaines techniques de prédilections :
                  </h2>
                  {expertise.map((currentItem) => {
                    const { category, items } = currentItem;
                    return (
                      <div
                        key={currentItem.id}
                        className="ekeep-micro-cast-member__description_second-part_expertise-category--item"
                      >
                        <h4>{category}</h4>
                        <InformationList informations={items} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            ): null}
            {hobbies.length > 0 ? (
              <>
                <div className="ekeep-micro-cast-member__description_second-part_job-hobbies">
                  <h2 className="ekeep-micro-cast-member__description_second-part_job-hobbies-title">
                    Hobbies
                  </h2>
                  <PersonalityList informations={hobbies} />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Bounce>
    </div>
  );
};
MicroCastMemberComponent.propTypes = microCastMemberPropType;
MicroCastMemberComponent.defaultPropTypes = {
  presentation: '',
  strongPoint: [],
  expertise: [],
  hobbies: [],
};
export default withOverlay(MicroCastMemberComponent);
