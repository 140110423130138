export const getArticleContent = (content) => {
    
    const contenu =  content.map(currentItem => ({
        content: currentItem.content.map(currentItem => ({
            content: currentItem.value
        }))
    }))
    const getContent = contenu.map(currentItem => ({
        value: currentItem.content[0]
    }))

    const getValue = getContent.map(currentItem => ({
        value: currentItem.value
    }))
    let value = [{}];
    for(let i in getValue[0]){
        value = getValue[0][i];
    }

    return value.content;

};

