import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Titles from './titles.json';

import './navPart.scss';
import './navPart.mobile.scss';

const NavPart = () => {
  const history = useHistory();
  const [state, setState] = useState({
    titles: Titles,
  });

  const openPage = (id) => () => {
    const page = state.titles.find((t) => t.id === id);
    const otherPages = state.titles.filter((t) => t.id != id);

    otherPages.forEach(el => el.isSelected = false);

    page.isSelected = true;

    setState({
      ...state,
      titles: [...state.titles],
    });
    history.push(page.url);
  };

  const mouseOver = (id) => () => {
    const { titles } = state;

    const page = titles.find((title) => title.id === id);

    const otherPages = titles.filter((title) => title.id != id);
    otherPages.forEach(el => el.isOvered = false);
    page.isOvered = true;

    setState({
      ...state,
      titles: [...state.titles],
    });

  }

  const mouseLeave = (id) => () => {
    const page = state.titles.find((t) => t.isSelected == true);
    const pageHover = state.titles.find((t) => t.id === id);
    
    pageHover.isOvered = false;

    setState({
      ...state,
      titles: [...state.titles],
    });
  }

  //Affiche le keepy de la page en cours dans le menu si access par url directe
  const location = useLocation();
  var currentPage = state.titles.find((t) => t.url == location.pathname);
  if (currentPage && !currentPage.isSelected) {
    currentPage.isSelected = true;
    setState({
      ...state,
      titles: [...state.titles]
    })
  }



  useEffect(() => {
    history.listen((location, action) => {
      var pages = state.titles;

      if (location.pathname == '/') {
        pages.forEach(el => {
          el.isSelected = false;
          el.isOvered = false;
        });
      }
      else {
        pages = pages.filter((el) => {
          return el.url == location.pathname;
        })
        pages.isSelected = true;

      }
      setState({
        ...state,
        titles: [...state.titles],
      });
    })

    const target = document.querySelector('.nav-part__target');
    const links = document.querySelectorAll('.nav-part a');
    const colors = ['#6effd8', '#ffffff'];

    links.forEach((l) => {
      l.addEventListener('click', (e) => e.preventDefault());
    });
  }, [state]);
  return (
    <>
      <div className="nav-part">
        <nav>
          <ul>
            {state.titles.map((t) => (
              <li
                key={`li_${t.id}`}
                className={`nav-part__item nav-part__${t.class}`}
              >

                <a
                  className={
                    t.isSelected
                      ? `nav-part__${t.class}_link--active`
                      : `nav-part__${t.class}_link`
                  }
                  href={t.url}
                  onClick={openPage(t.id)}
                  onMouseEnter={mouseOver(t.id)}
                  onMouseLeave={mouseLeave(t.id)}
                >
                  {t.logo ? (
                    <picture id="header-bottom__first-part_logo">
                      <source media="(min-width: 800px)" srcSet={t.logo} />
                      <img
                        className="header-bottom__first-part_logo--resize"
                        alt="ekeep-it"
                        src={t.logo}
                      />
                    </picture>
                  ) : ''
                  }

                  {t.img && (t.isSelected || t.isOvered) ? (
                    <picture id="header-bottom__second-part_img">
                      <source media="(min-width: 800px)" srcSet={t.img} />
                      <img
                        className="header-bottom__second-part_img"
                        alt="ekeep-it"
                        src={t.img}
                      />
                    </picture>
                  ) : t.title}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <span className="nav-part__target" />
      </div>
    </>
  );
};

export default NavPart;
