import React from 'react';
import { func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ReturnBack } from '../../../shared/ReturnBack';
import withOnReturnBack from './withOnReturnBack';

import './detailsoffers.scss';
import './detailsoffers.mobile.scss';

const propTypes = {
  onReturnBack: func.isRequired,
};

const DetailsOffers = ({ onReturnBack }) => {
  const history = useHistory();
  return (
    <div className="container-offer">
      <div
        className="container-offer__dev"
        onClick={() => {
          history.push('/nos-opportunites/offres/java/developpeur');
        }}
      >
        <div className="container-offer__dev-circle">
          <div className="container-offer__dev-circle-wrapper">
            <p>Developpeur Java</p>
          </div>
        </div>
      </div>
      <div
        className="container-offer__tl"
        onClick={() => {
          history.push('/nos-opportunites/offres/java/leader-technique');
        }}
      >
        <div className="container-offer__tl-circle">
          <div className="container-offer__tl-circle-wrapper">
            <p>Leader Technique Java</p>
          </div>
        </div>
      </div>
      <div
        className="container-offer__chef"
        onClick={() => {
          history.push('/nos-opportunites/offres/java/chef-de-projet');
        }}
      >
        <div className="container-offer__chef-circle">
          <div className="container-offer__chef-circle-wrapper">
            <p>Chef de projet Java</p>
          </div>
        </div>
      </div>
      <div className="container-offer__return">
        <ReturnBack onReturnBack={onReturnBack} btnText="Retour aux offres" />
      </div>
    </div>
  );
};
DetailsOffers.propTypes = propTypes;

export default withOnReturnBack(DetailsOffers);
