import React from 'react';
import MicrosoftAndJavaProfile from '../../../../public/images/PROFILS_TECHNIQUES.jpg';
import TypeOfProfile from '../../../../public/images/TYPES_DE_PROFILS.jpg';
import AuditAndExpertise from '../../../../public/images/AUDIT_ET_EXPERTISE.jpg';
import UnderstandTeams from '../../../../public/images/ECHANGE_CLIENT_ECOUTE.jpg';
import QualificationsOfProfils from '../../../../public/images/QUALIFICATION.jpg';
import Challenge from '../../../../public/images/CONSULTANTS_CHALLENGE.jpg';

import './customer.scss';
import './customer.mobile.scss';



const Customer = () => {
  return (
    <>
      <div className="customers-valors-container">
        <h2 className="customers-valors-container__title">Ce que nous proposons à nos clients</h2>
        <div className="customers-valors-container__boxes">
          <ul>
            <li>
              <span class='customers-valors-container__boxes__item-pic'>
                <img src={MicrosoftAndJavaProfile} placeholder />
              </span>
              <span className='customers-valors-container__boxes__valors'>
                <span className="customers-valors-container__boxes__item-text">DOMAINES TECHNIQUES</span>
                <span className='customers-valors-container__boxes__item-detail'>
                  <p>Back-end /Front-end / Full-stack / DevOps</p>
                  <p>Java / Microsoft / Python / Angular React / Vue / Docker</p>
                </span>
              </span>

            </li>
            <li>
              <span class='customers-valors-container__boxes__item-pic profils'>
                <img src={TypeOfProfile} placeholder />
              </span>
              <span className='customers-valors-container__boxes__valors'>
                <span className="customers-valors-container__boxes__item-text">PROFILS</span>
                <span className='customers-valors-container__boxes__item-detail'>
                  <ul>
                    <li>Concepteur développeur</li>
                    <li>Devops</li>
                    <li>Référent technique</li>
                    <li>Leader technique</li>
                    <li>Architecte</li>
                    <li>Chef de projet</li>
                    <li>Directeur de projet</li>
                  </ul>
                </span>
              </span>
            </li>
            <li>
              <span class='customers-valors-container__boxes__item-pic'>
                <img src={UnderstandTeams} placeholder />
              </span>
              <span className='customers-valors-container__boxes__valors'>
                <span className="customers-valors-container__boxes__item-text">FONCTIONNEMENTS</span>
                <span className='customers-valors-container__boxes__item-detail'>
                  <ul>
                    <li>Régie</li>
                    <li>Audit</li>
                    <li>Formations</li>
                  </ul>

                </span>
              </span>
            </li>

            <li>
              <span class='customers-valors-container__boxes__item-pic'>
                <img src={AuditAndExpertise} placeholder />
              </span>
              <span className='customers-valors-container__boxes__valors'>
                <span className="customers-valors-container__boxes__item-text">MISSIONS</span>
                <span className='customers-valors-container__boxes__item-detail'>
                  <ul >
                    <li>
                      Échanger et comprendre <br />{' '}
                      <p >
                        vos besoins ET vos équipes
                      </p>
                    </li>
                  </ul>
                </span>
              </span>
            </li>

            <li>
              <span class='customers-valors-container__boxes__item-pic'>
                <img src={QualificationsOfProfils} placeholder />
              </span>
              <span className='customers-valors-container__boxes__valors'>
                <span className="customers-valors-container__boxes__item-text">ATOUTS</span>
                <span className='customers-valors-container__boxes__item-detail'>
                  <ul>
                    <li>Passionés</li>
                    <li>Qualités techniques</li>
                    <li>Qualités humaines</li>
                  </ul>
                </span>
              </span>
            </li>

            <li>
              <span class='customers-valors-container__boxes__item-pic'>
                <img src={Challenge} placeholder />
              </span>
              <span className='customers-valors-container__boxes__valors'>
                <span className="customers-valors-container__boxes__item-text">Des challengers avec une conscience profesionnelle</span>
                <span className='customers-valors-container__boxes__item-detail'>
                <p></p>
                </span>
              </span>
            </li>           
           
          </ul>
        </div>
      </div>
    </>
  );
};

export default Customer;
