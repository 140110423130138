import React, { useContext, useEffect, useState } from 'react';
import useContentfull from '../shared/useContentfull';
import { mapDataFromApiResult } from '../Ekeep/LifeWithUs/service'
import StampNews from './StampNews';

const query = `
        query {
            articlesCollection(order: releaseDate_DESC){
            items{
                sys {
                    id
                }   
                slug,
                url,
                slug,
                title,
                releaseDate,
                description,
                content{
                    json
                },
                firstImage{
                    title
                    url
                },
                multiPhotoCollection{
                    items{
                        sys {
                            id
                        }
                        title
                        url
                    }
                },
                status
            }
        }
    }
`;

const StampNewsContainer = () => {

    const { data, loading, errors } = useContentfull(query);
    const [visibility, setVisibility] = useState(false);
    const [state, setState] = useState({});

    useEffect(() => {
        if (!loading) {
            if(data) {
                let articleInfo = mapDataFromApiResult(data);
                setState({
                    ...state,
                    articleInfo
                });
            }
        }
    }, [data, loading, errors]);

    return (
        <StampNews
            {...state}
            articleInfo={state.articleInfo}
        />
    );


};

export default StampNewsContainer;