import Microsoft from '../../../../public/images/MICROSOFT.jpg';
import Java from '../../../../public/images/JAVA.jpg';
import Other from '../../../../public/images/AUTRES.jpg';

export default [
  {
    id: 'a82f0f90-7689-44fa-bda3-c133303219cd',
    component: Java,
    title: 'Je suis orienté(e) Open Sources Java',
    type: 'java',
  },
  {
    id: '87f35ce8-1395-429f-808c-caba824fafb1',
    component: Microsoft,
    title: 'Je suis orienté(e) Microsoft',
    type: 'microsoft',
  },
  {
    id: '7bd0ac9e-ea78-41e4-84b4-0c95792ecdc9',
    component: Other,
    title: 'Autres ...',
    type: 'devops',
  },
];
