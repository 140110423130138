import React from 'react';
import { withRouter } from 'react-router-dom';
import { string, func, bool, shape } from 'prop-types';

const propTypes = {
  id: string.isRequired,
  firstName: string.isRequired,
  active: bool.isRequired,
  openProfile: func.isRequired,
  history: shape({}).isRequired,
  post: string,
  src: string,
  className: string,
};

const Member = ({
  id,
  firstName,
  src,
  active,
  openProfile,
  post,
  history,
  className,
}) => {
  const meetEkeep = () => history.push('/nos-opportunites#meet-ekeep');
  return (
    <>
      <img
        id={`ekeep-macro__content_${id}`}
        alt={`${firstName}`}
        src={src}
        onClick={active ? openProfile(id) : meetEkeep}
        className={className}
      />
      <span className="first-and-last-name">{`${firstName}`}</span>
      <span className="post">{post}</span>
    </>
  );
};

Member.propTypes = propTypes;
Member.defaultProps = {
  src: '',
  post: '',
  className: '',
};

export default withRouter(Member);
