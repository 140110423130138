import React, { createContext, useEffect, useState } from 'react';
import { Loader, LoaderMode } from './shared/loader';

export const EnvironmentContext = createContext('environment');

export const EnvironmentConsumer = EnvironmentContext.Consumer;

export const withEnvironment = (Component) => (props) => (
  <EnvironmentConsumer>
    {(store) => <Component {...props} {...store} />}
  </EnvironmentConsumer>
);

const getEnvironmentData = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Access-Control-Allow-Origin", '*');
  var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
  const fileName =
    process.env.NODE_ENV === 'development'
      ? 'environment.dev.json'
      : 'environment.json';
  const response = await fetch(`/${fileName}`,myInit);
  const result = await response.json();
  return result;
};

const EnvironmentProvider = ({ children }) => {
  const [environment, setEnvironment] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEnvironmentData().then((data) => {
      setEnvironment({ environment: data });
      setLoading(false);
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loader loaderMode={LoaderMode.get} text="Loading..." />
      ) : (
        <EnvironmentContext.Provider value={environment}>
          {children}
        </EnvironmentContext.Provider>
      )}
    </>
  );
};

export default EnvironmentProvider;

