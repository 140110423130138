import React from 'react';
import { string } from 'prop-types';

const propTypes = {
  firstName: string.isRequired,
  post: string.isRequired,
};
const Post = ({ firstName, post, type }) => {
  return (
    <div
      className={
        type
          ? `ekeep-micro-${type}__description_first-part`
          : 'ekeep-micro__description_first-part'
      }
    >
      <h1
        className={
          type
            ? `ekeep-micro-${type}__description_first-part--name`
            : 'ekeep-micro__description_first-part--name'
        }
      >{firstName}</h1>
      <h2
        className={
          type
            ? `ekeep-micro-${type}__description_first-part--age`
            : 'ekeep-micro__description_first-part--age'
        }
      >
      </h2>
      <h2
        className={
          type
            ? `ekeep-micro-${type}__description_first-part--post`
            : 'ekeep-micro__description_first-part--post'
        }
      >
        {post}
      </h2>
    </div>
  );
};
Post.propTypes = propTypes;

export default Post;
