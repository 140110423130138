/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { shape, string, bool, arrayOf, func } from 'prop-types';
import { ReturnBack } from '../../../../shared/ReturnBack';
import withOnReturnBack from '../withOnReturnBack';

import './offer.scss';
import './offer.mobile.scss';

const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
};

const offerChefPropTypes = {
  principalTitle: string.isRequired,
  rules: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  description: string.isRequired,
  qualities: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  technologies: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  onReturnBack: func.isRequired,
  type: string,
};

const OfferChef = ({
  principalTitle,
  rules,
  qualities,
  description,
  technologies,
  type,
  onReturnBack,
}) => {
  return (
    <section className="offers-container-chef">
      <h1 className="offers-container-chef__title">{principalTitle}</h1>
      <article className="offers-container-chef__description">
        <p>{description}</p>
      </article>
      <article className="offers-container-chef__rules">
        <header>
          <h3>Votre Role</h3>
          <ul>
            {rules.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <article className="offers-container-chef__qualities">
        <header>
          <h3>Vos Qualités</h3>
          <ul>
            {qualities.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <article className="offers-container-chef__technologies">
        <header>
          <h3>Vos Technos</h3>
          <ul>
            {technologies.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <ReturnBack
        onReturnBack={onReturnBack}
        btnText={`Retour aux offres ${
          type === 'microsoft' || type === 'java' ? capitalize(type) : ''
        }`}
      />
    </section>
  );
};
OfferChef.propTypes = offerChefPropTypes;
OfferChef.defaultProps = {
  type: '',
};

const offerPropTypes = {
  principalTitle: string.isRequired,
  postes: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  sectors: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  technologies: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  goodPratices: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  mindsets: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  onReturnBack: func.isRequired,
  type: string,
};

const Offer = ({
  principalTitle,
  postes,
  sectors,
  technologies,
  goodPratices,
  mindsets,
  type,
  onReturnBack,
}) => {
  return (
    <section className="offers-container">
      <h1 className="offers-container__title">{principalTitle}</h1>
      <article className="offers-container__poste">
        <header>
          <h3>Poste</h3>
          <ul>
            {postes.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <article className="offers-container__sectors">
        <header>
          <h3>Secteurs</h3>
          <ul>
            {sectors.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <article className="offers-container__technologies">
        <header>
          <h3>Technologies</h3>
          <ul>
            {technologies.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <article className="offers-container__good-pratices">
        <header>
          <h3>Bonnes pratiques</h3>
          <ul>
            {goodPratices.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <article className="offers-container__mindsets">
        <header>
          <h3>État d'esprit</h3>
          <p>Profil avec de l’expérience et passionné par la technique qui :</p>
          <ul>
            {mindsets.map((p) => {
              return <li key={p.id}>{p.title}</li>;
            })}
          </ul>
        </header>
      </article>
      <ReturnBack
        onReturnBack={onReturnBack}
        btnText={`Retour aux offres ${
          type === 'microsoft' || type === 'java' ? capitalize(type) : ''
        }`}
      />
    </section>
  );
};
Offer.propTypes = offerPropTypes;
Offer.defaultProps = {
  type: '',
};

const offerChoicePropTypes = {
  projectChef: bool,
};

const OfferChoice = ({ projectChef, ...otherProps }) => {
  if (projectChef) {
    return (
      <>
        <OfferChef {...otherProps} />
      </>
    );
  }
  return (
    <>
      <Offer {...otherProps} />
    </>
  );
};
OfferChoice.propTypes = offerChoicePropTypes;
OfferChoice.defaultProps = false;

export default withOnReturnBack(OfferChoice);
