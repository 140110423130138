import React from 'react';
import { ContactUsForm } from '../MeetEkeep/Contacts';
import './apply.css';
import './apply.mobile.scss';

const Apply = () => {
    return (
        <div className="contact-us-container">
            <ContactUsForm />
        </div>
    );
};
export default Apply;
