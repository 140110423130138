import React, { useState, useEffect } from 'react';
import FlipCounter from './FlipCounter'

const FlipCounterContainer = ({ value, min, max, currency }) => {
    const [isActive, setIsActive] = useState(false);
    const [val, setVal] = useState(value);
    const isMobile = navigator?.userAgentData?.mobile;

    function stopRandom() {
        setIsActive(false);
    }
    useEffect(() => {
        if (isMobile!= undefined && !isMobile) {
            let interval = null;
            if (isActive) {
                interval = setInterval(() => {
                    setVal(val => {
                        var rnd = (Math.floor(Math.random() * (max - min)) + min);
                        return rnd + (currency ? currency : '');
                    });
                }, 100);
            } else if (!isActive) {
                clearInterval(interval);
                setVal(val => val = value)
            }
            return () => clearInterval(interval);
        }
    }, [isActive, val]);

    return (
        <div onMouseOver={() => setIsActive(true)} onMouseLeave={stopRandom}>
            <FlipCounter value={val} />
        </div>
    )
}

export default FlipCounterContainer;