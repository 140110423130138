import React from 'react';
import { useHistory } from 'react-router-dom';

export default (Component) => (props) => {
  const history = useHistory();
  const onReturnBack = () => {
    switch (props.type) {
      case 'java':
        history.push('/nos-opportunites/offres/java');
        break;
      case 'microsoft':
        history.push('/nos-opportunites/offres/microsoft');
        break;
      case 'devops':
        history.push('/nos-opportunites/offres/devops');
        break;
      default:
        history.push('/nos-opportunites');
        break;
    }
  };
  return <Component onReturnBack={onReturnBack} {...props} />;
};
