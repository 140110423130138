import React from 'react';
import Offer from './Offer';
import { Microsoft, Java, DevOps } from './Offers';

export const DevMicrosoft = () => <Offer {...Microsoft[0]} />;

export const TlMicrosoft = () => <Offer {...Microsoft[1]} />;

export const ChefMicrosoft = () => <Offer {...Microsoft[2]} />;

export const DevJava = () => <Offer {...Java[0]} />;

export const TlJava = () => <Offer {...Java[1]} />;

export const ProjectChefJava = () => <Offer {...Java[2]} />;

export const OfferDevOps = () => <Offer {...DevOps[0]} />;
