import React, { useEffect } from 'react';
import LoaderMode from './LoaderMode';

import './loader.scss';
import './loader.mobile.scss';

const texts = {
  get: 'Chargement en cours',
  post: 'Sauvegarde en cours',
  delete: 'Suppression en cours',
  update: 'Mise à jour en cours',
  error: 'Une erreur est survenue lors du chargement du composant',
};

const Loader = ({ loaderMode = LoaderMode.get, ...otherProps }) => {
  const isHidden = loaderMode !== LoaderMode.get;

  useEffect(() => {
    if (!isHidden) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }
  });

  return (
    <>
      <div
        className={`loader-container loader-container${
          isHidden ? '--off' : '--on'
        }`}
      >
        <div role="alert" name="loader" className="loader-container-circles" />
      </div>
      {otherProps.children}
    </>
  );
};

export default Loader;
