import { format, parseISO } from 'date-fns';
import { getArticleContent } from '../../../helper';

export const mapDataFromApiResult = (data) => {

    const article = data.articlesCollection.items;
    return article.map((art => {
        const date = art.releaseDate;
        const dateFormat = format(parseISO(date), "dd/MM/yyyy");

        const content = getArticleContent(art.content.json.content);

        return {
            _id: art.slug,
            title: art.title,
            subTitle: 'Paru le',
            description: art.description, 
            content: content,
            date: dateFormat,
            firstImage: art.firstImage.url,
            status: art.status,
            imgList: art.multiPhotoCollection.items && art.multiPhotoCollection.items.length > 0 ? art.multiPhotoCollection.items.map(currentItem => ({
                id: currentItem.sys.id,
                img: currentItem.url,
                name: currentItem.title
            })) : [],
        }
    }));
    
}