import React from 'react';
import FadeInSection from '../shared/FadeInSection';
import { StampNews } from '../StampNews';
import { FlipCounterContainer } from '../FlipCounter';

import GreatPlaceToWork from '../../public/images/GreatPlaceToWork_certified_Mars2022_CMJN.jpg';

import './home.scss';
import './home.mobile.scss';
import '../shared/fadeinsection.scss';

const Home = () => {
  return (
    <div className="home">
      <div className="home__container">
        <div id="stamp-news-row" className="home__stamp-news-row">
          <FadeInSection>
            <StampNews></StampNews>
          </FadeInSection>
        </div>
        <div className="home__container__bottom">
          <div className="home__container__keydigits">
            <h1 className="home__container__facts-title">
              Ekeep it en quelques chiffres sur 2022
            </h1>
            <div className="home__container__facts">
              <div className="home__container__facts-flips">
                <div className="home__container__facts-flip">
                  <span className="home__container__facts-flip-title">
                    {"Un chiffre d'affaire de"}
                  </span>
                  <FlipCounterContainer
                    value={'3000000€'}
                    min={1000000}
                    max={9000000}
                    currency={'€'}
                  />
                </div>
                <div className="home__container__facts-flip">
                  <span className="home__container__facts-flip-title">
                    Nombre de salariés
                  </span>
                  <FlipCounterContainer value={25} min={10} max={99} />
                </div>
                <div className="home__container__facts-flip">
                  <span className="home__container__facts-flip-title">
                    Nombre de stagiaires reçus
                  </span>
                  <FlipCounterContainer value={5} min={0} max={9} />
                </div>
                <div className="home__container__facts-flip">
                  <span className="home__container__facts-flip-title">
                    {"Nombre d'indépendants et de sous traitants"}
                  </span>
                  <FlipCounterContainer value={19} min={10} max={99} />
                </div>
                <div className="home__container__facts-flip">
                  <span className="home__container__facts-flip-title">
                    Nombre de clients actifs
                  </span>
                  <FlipCounterContainer value={13} min={1} max={9} />
                </div>
                <div className="home__container__facts-flip">
                  <span className="home__container__facts-flip-title">
                    Nombre de fidèles à nous suivre
                  </span>
                  <FlipCounterContainer value={1392} min={100} max={999} />
                </div>
              </div>
            </div>
          </div>
          <div className="home__container__certif">
            <img src={GreatPlaceToWork}></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
