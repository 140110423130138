import React, { useEffect } from 'react';

import Article from '../Ekeep/Articles/Article';
import { Delayed } from '../Delay';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import './stampnews.scss';
import './stampnews.mobile.scss';

import stampsLeft from '../../public/images/Tampon_Humain.gif';
import stampRight from '../../public/images/Tampon_Technique.gif';
import joinedStamps from '../../public/images/joinedStamps.gif';

const StampNews = ({...props}) => {

    const {
        articleInfo
    } = props;

    useEffect(() => {
        var imgs = document.querySelectorAll('.post-desktop');
        imgs.forEach(el => {
            el.classList.add("articleResize");
        })
    }, []);


    return (
        <>
            <h1 className="stampnews-title">Notre actualité</h1>
            <div className="stampnews-container ">
                <div className="stampnews-container__joined">
                    <img className="stampnews-container__img" src={joinedStamps}></img>
                </div>
                <div className="stampnews-container__left">
                    <img className="stampnews-container__img" src={stampsLeft}></img>
                </div>
                <div className="stampnews-container__middle ">
                    {articleInfo && articleInfo.length > 0 ? (
                        <Carousel
                            className=""
                            showThumbs={false}
                            showArrows={true}
                            showStatus={false}
                            swipeable={true}
                            interval={2000}
                            transitionTime={350}
                            autoPlay={true}
                            infiniteLoop={true}
                            showIndicators={false} >

                            {articleInfo.map((article) => (
                                <a href="/la-vie-avec-nous">
                                    <Article {...article} textButton="En savoir plus" />
                                </a>
                            ))}
                        </Carousel>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="stampnews-container__right">
                    <Delayed>
                        <img className="stampnews-container__img" src={stampRight}></img>
                    </Delayed>
                </div>
            </div>
        </>
    )
}

export default StampNews;