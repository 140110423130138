import React from 'react';

export default (Component) => (props) => {
  const { visibility, onClose } = props;
  return (
    <div
      className={`ekeep-micro ekeep-micro__overlay ekeep-micro__overlay${
        visibility ? '--visible' : '--hidden'
      } `}
      onClick={onClose}
    >
      <Component {...props} />
    </div>
  );
};
