import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

export default (WrappedComponent, options = {}) => (props) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };
  useEffect(() => {
    const {
      location: { pathname: page },
    } = props;
    trackPage(page);
  }, []);
  return <WrappedComponent {...props} />;
};
