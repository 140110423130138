import { useState, useEffect } from 'react';

const useContenfull = (query) => {
  let [data, setData] = useState([]);
  let [errors, setErrors] = useState(null);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataFromContentFull = async () => {
      try {
        const response = await fetch(
          `https://graphql.contentful.com/content/v1/spaces/${process.env.RAZZLE_CTF_SPACE_ID}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.RAZZLE_CTF_TOKEN}`,
            },
            body: JSON.stringify({ query }),
          },
        );
        const { data, errors } = await response.json();
        if (errors) setErrors(errors);
        if (data) {
          setData(data);
        }
        setLoading(false);
      } catch (error) {
        setErrors([error]);
      }
    };
    getDataFromContentFull();
  }, [query]);

  return { data, errors, loading };
};

export default useContenfull;