import React, { useState, useEffect } from 'react';
import LifeWithUs from './LifeWithUs';
import useContentfull from '../../shared/useContentfull';
import { mapDataFromApiResult } from './service';

const query = `
    query {
        articlesCollection(order: releaseDate_DESC){
        items{
            sys {
            id
            }
            slug,
            url,
            slug,
            title,
            releaseDate,
            description,
            content{
            json
            },
            firstImage{
            title
            url
            },
            multiPhotoCollection{
            items{
                sys {
                id
                }
                title
                url
            }
            },
            status
        }
        }
    }
`;

const LifeWithUsContainer = () => {

    const { data, loading, errors } = useContentfull(query);
    const [visibility, setVisibility] = useState(false);
    const [state, setState] = useState({});

    useEffect(() => {
        if (!loading) {
            let articleInfo = mapDataFromApiResult(data);
            setState({
                ...state,
                articleInfo
            });
        }
    }, [data, loading, errors]);

    return (
        <LifeWithUs
            {...state}
            visibility={visibility}
            setVisibility={setVisibility}
            articlesItemList={state.articleInfo}
        />
    );
};

export default LifeWithUsContainer;