import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { DropzoneArea } from 'material-ui-dropzone';
import ReCAPTCHA from 'react-google-recaptcha';
import { shape, string, bool, func, number } from 'prop-types';
import {
  CV_FILES,
  FEW_WORD,
  FIRST_NAME,
  LAST_NAME,
  POSITION,
  EMAIL_ADRESS,
  PHONE_NUMBER,
} from './ContactUsForm.service';

import './contact-us-form.scss';
import './contact-us-form.mobile.scss';

const fieldShape = shape({
  value: string,
  error: bool,
  helperText: string,
});

const propTypes = {
  fields: shape({
    firstName: fieldShape.isRequired,
    lastName: fieldShape.isRequired,
    position: fieldShape.isRequired,
    fewWord: fieldShape.isRequired,
    phoneNumber: fieldShape.isRequired,
    emailAddress: fieldShape.isRequired,
  }).isRequired,
  captcha: shape({
    callback: string,
    value: string,
    load: bool,
    delay: number,
  }).isRequired,
  siteKey: string.isRequired,
  onChange: func.isRequired,
  onChangeCaptcha: func.isRequired,
  asyncScriptOnLoad: func.isRequired,
  onHandleFile: func.isRequired,
  onSendMail: func.isRequired,
};

const ContactUsForm = ({ ...props }) => {
  const {
    fields: {
      firstName,
      lastName,
      position,
      fewWord,
      phoneNumber,
      emailAddress,
    },
    captcha,
    siteKey,
    onChange,
    onChangeCaptcha,
    asyncScriptOnLoad,
    onHandleFile,
    onSendMail,
  } = props;

  return (
    <>
      
      <form
        itemProp="contact"
        itemScope="http://schema.org/ContactPage"
        className="contact-form"
        action="/send-email"
        method="post"
        autoComplete="off"
      >
        <h1>Formulaire temporairement désactivé</h1>
        <TextField
          disabled={true}
          itemProp="name"
          required
          id="first-name"
          label="Nom"
          name={FIRST_NAME}
          variant="outlined"
          className="contact-form__field contact-form__field-name"
          onChange={onChange}
          {...firstName}
        />
        <TextField
          disabled={true}
          itemProp="name"
          required
          id="last-name"
          label="Prénom"
          name={LAST_NAME}
          variant="outlined"
          className="contact-form__field"
          onChange={onChange}
          {...lastName}
        />
        <TextField
          disabled={true}
          required
          id="email-adress"
          label="Adresse mail"
          name={EMAIL_ADRESS}
          variant="outlined"
          className="contact-form__field contact-form__field-email"
          onChange={onChange}
          {...emailAddress}
        />
        <TextField
          disabled={true}
          id="phone-number"
          label="Numéro de téléphone"
          name={PHONE_NUMBER}
          variant="outlined"
          className="contact-form__field"
          onChange={onChange}
          {...phoneNumber}
        />
        <TextField
          disabled={true}
          required
          id="position"
          label="Poste souhaité"
          name={POSITION}
          variant="outlined"
          className="contact-form__field"
          onChange={onChange}
          {...position}
        />
        <div
          id="cv"
          className="contact-form__field contact-form__field-drop-file"
        >
          {/* <DropzoneArea
          disabled={true}
          id="cv-files"
          name={CV_FILES}
          onDrop={onHandleFile}
          onChange={onHandleFile}
          acceptedFiles={['application/pdf']}
          dropzoneText="J'envoie mon CV"
          maxFileSize={5000000}
          clearOnUnmount
        /> */}
        </div>
        <TextField
          disabled={true}
          itemProp="comment"
          id="few-words"
          label="Un petit mot pour nous ?"
          name={FEW_WORD}
          multiline
          rows={4}
          variant="outlined"
          className="contact-form__field"
          onChange={onChange}
          {...fewWord}
        />
        {captcha.load && (
          <ReCAPTCHA
            hidden={true}
            className="contact-form__field contact-form__field-captcha"
            theme="dark"
            sitekey={siteKey}
            onChange={onChangeCaptcha}
            asyncScriptOnLoad={asyncScriptOnLoad}
          />
        )}
        <Button
          disabled={true}
          variant="contained"
          color="primary"
          className="contact-form__field contact-form__field-btn-send-mail"
          startIcon={<SendIcon />}
          onClick={onSendMail}
        >
          Envoyer
        </Button>
      </form>
    </>
  );
};
ContactUsForm.propTypes = propTypes;

export default ContactUsForm;
