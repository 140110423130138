/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumbs, propTypes } from '../BreadCrumbs';

import NavPart from './NavPart';

import './header.scss';
import './header.mobile.scss';
import './header.tablet.scss';

import Logo from '../shared/logo/logo_CMJN_invis_reworked.png';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import House from '@material-ui/icons/House';

const Header = ({ crumbs }) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        // margin: theme.spacing(1),
        fontSize: '2px'
      },

    },
    button: {
      backgroundColor: '#004766',
      color: 'white',
      content: '"some content"',
      '&:hover': {
        backgroundColor: '#004766',
      },
      '&:hover::after': {

        content: '"Contact"',
        display: 'block',

      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const goHome = () => {
    history.push('/');
  };
  const goContact = () => {
    history.push('/contact');
  }

  return (
    <>
      <div className="header-container header-top--visibility">
        <nav className="navbar navbar-light header-top__navbar header-container__test">
          <div className="header-top__navbar-social">
            <ul className="navbar-social__options">
              <li className="navbar-social__options-menu--visibility">
                <div className="menuToggle">
                  <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span>
                  <ul className="menu">
                    <li>
                      <a href="/">Accueil</a>
                    </li>
                    <li>
                      <a href="/qui-sommes-nous">Qui sommes nous ?</a>
                    </li>
                    <li>
                      <a href="/nos-valeurs">Nos valeurs</a>
                    </li>
                    <li>
                      <a href="/nos-opportunites">Nos opportunités</a>
                    </li>
                    <li>
                      <a href="/je-postule">Je postule</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </li>           
            </ul>
          </div>
          <div className="header-top__navbar-information">
            <ul className="header-top__navbar-information-values">
              <li className="header-top__navbar-information-values--visible">
                <a href="/">
                  <FontAwesomeIcon icon={faHome} />
                </a>
              </li>
              <li className="header-top__navbar-information-values--visible">
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <header className="header--visibility">
        <div className="header-container shadowBottom">
          <div className='header-contact'>
            <Fab variant="extended" className={classes.button} onClick={goContact}>
              <House className={classes.extendedIcon} />
            </Fab>
          </div>
          <div className="header__logo">
              <a onClick={goHome}>
                <picture>
                  <source media="(min-width: 800px)" srcSet={Logo} />
                  <img
                    className="header__logo__img"
                    alt="ekeep-it"
                    src={Logo}
                  />
                </picture>
              </a>
            </div>
          <div id="header-bottom" className="header-bottom">            
            <div className="header-bottom__second-part">
              <NavPart />
            </div>
            <div id="header-bread" className="header-bottom__third-part">
              <Breadcrumbs crumbs={crumbs} />
            </div>
          </div>

        </div>
      </header>
    </>
  );
};
Header.propTypes = propTypes;

export default Header;
