import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Cookies } from 'react-cookie-consent';
import { ToastProvider } from 'react-toast-notifications';
import Routes from './Routes';
import EnvironmentProvider, { withEnvironment } from './EnvironmentProvider';

import './App.scss';
import './app.mobile.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const AllRoutes = withEnvironment(Routes);

const App = () => {
  useEffect(() => {
    ReactGA.initialize('UA-184056416-1');
  }, []);
  return (
    <EnvironmentProvider>
          <ToastProvider>
            <AllRoutes />
          </ToastProvider>
    </EnvironmentProvider>
  );
};

export default App;
