import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { string } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';

import { classModifierIsAlreadySet } from '../shared/ClassModifier';

import './footer.scss';
import './footer.mobile.scss';


const propTypes = {
  classModifier: string,
};


const FooterPart = ({ classModifier = null }) => {

  useEffect(() => {
    const footerElement = document.getElementById('footer');
    const classModifierComposed = `footer--${classModifier}`;

    if (classModifier != null) {
      if (classModifierIsAlreadySet(footerElement, classModifier)) {
        footerElement.classList.remove(classModifierComposed);
      } else {
        footerElement.classList.add(classModifierComposed);
      }
    }
    return () => {
      if (classModifierIsAlreadySet(footerElement, classModifier)) {
        footerElement.classList.remove(classModifierComposed);
      }
    };
  });
  return (
    <footer id="footer" className="footer">
      <div className="footer__mentions">
        <span>&copy; 2020 EKEEP-IT</span>
        <span className="separator">-</span>
        <a
          id="footer-legal-notice"
          className="footer__mentions__legals-mentions"
          href="/mentions-legales"
        >
          Mentions légales
        </a>
        <span className="separator">-</span>
        <a id="footer-site-map" className="footer__mentions__site-map" href="/plan-du-site">
          Plan du site
        </a>
        <span className="separator">-</span>

        <a className="footer__mentions__contact" href="/contact">Contact</a>

        <span className="separator">-</span>
        <a
          className="footer__mentions__linkedin"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/%C3%A9keep-it/"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      </div>
      </footer>
  );
};

FooterPart.defaultProps = {
  classModifier: null,
};
FooterPart.propTypes = propTypes;

export default withRouter(FooterPart);
