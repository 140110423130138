import React from 'react';
import Loader from './loader';

const withLoader = (Component) => ({ loaderMode, ...props }) => {
  return (
    <>
      <Loader loaderMode={loaderMode}>
        <Component {...props} />
      </Loader>
    </>
  );
};

export default withLoader;
