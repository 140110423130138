import React from 'react';
import { ReactComponent as IsotypeDarkBlue } from '../../../../shared/logo/isotype-dark-blue.svg';

import './ceo-contacts.scss';
import './ceo-contacts.mobile.scss';
import map from '../../../../../public/images/maps.jpg';
const CeoContact = () => {
  return (
    <>
      <section
        itemProp="director"
        itemScope="http://schema.org/Person"
        className="meet-ekeep__ceo-contact"
      >
        <IsotypeDarkBlue className="ceo-contact__smiley" />
        <p itemProp="adresse">CHATEAU BLEU, 1 ter Av. de la Marne, 59290 Wasquehal </p>
        <p itemProp="phone">06 30 88 57 75</p>        
      </section>
      <div className="container">
      <iframe className="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1503.1854969583221!2d3.115050709698881!3d50.68196016478529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c329772576c92f%3A0x16d4bd1ca05e324c!2s1%20ter%20Av.%20de%20la%20Marne%2C%2059290%20Wasquehal!5e0!3m2!1sfr!2sfr!4v1674736956838!5m2!1sfr!2sfr" ></iframe>
      </div>
    </>
  );
};

export default CeoContact;
