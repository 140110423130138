import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { EkeepMacroComponent } from '../Presentation/Macro';
import { CloudTagCustomerContainer } from '../../CloudTag';

import './whoAreUs.scss';
import './who-are-us.mobile.scss';

const WhoAreUs = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Esn à taille humaine dans la métropole lilloise"
        />  
      </Helmet>
      <div className="who-are-us">
        <EkeepMacroComponent />
        <div className="trust-us-container">
          <span className="trust-us-container__title">Ils nous font confiance</span>
        </div>
        <CloudTagCustomerContainer />
      </div>
    </>
  );
};

export default WhoAreUs;