import React from 'react';
import { ArticleList } from '../Articles';
import './lifeWithUs.scss';
import './lifeWithUs.mobile.scss';


const LifeWithUs = ({ ...props }) => {

  const {
    articleInfo
  } = props;

  return (
    <div className="life-with-you">
      <ArticleList articlesItemList={articleInfo} />
    </div>
  );
};

export default LifeWithUs;
